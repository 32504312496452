@import "styles/redesigned/functions";
@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.walletOverview {
	h2 {
		margin-top: rem(70);
	}

	.account,
	.wallet {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(12);
		border-radius: rem(16);
		background-color: var(--redesigned-color-background-base-3);
	}

	.account,
	.wallet,
	.asset {
		.actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rem(12);
		}
	}

	.account {
		padding: rem(4);
		padding-right: rem(20);

		.character {
			margin-right: rem(16);
			width: rem(56);
			height: rem(56);
			border-radius: rem(12);
			background-color: var(--redesigned-color-background-primary);
		}

		.infos {
			margin-left: auto;
			display: flex;
			flex-direction: row;
			align-items: center;

			& > .info:not(:last-child) {
				position: relative;
				padding-right: rem(16);
				margin-right: rem(16);

				&:after {
					content: "";
					display: block;
					position: absolute;
					width: rem(1);
					height: calc(100% - #{rem(8)});
					top: rem(4);
					right: 0;
					background-color: var(--redesigned-color-separator-opaque);
				}
			}
		}

		.info {
			& > div:first-child {
				display: block;
				margin-bottom: rem(2);
				@include font(10, 500, 10);
				color: var(--redesigned-color-label-secondary);
			}

			& > div:last-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(4);
				margin-bottom: rem(2);
				@include font(14, 400, 20);

				&.name {
					font-weight: 500;
				}

				button {
					width: rem(14);
					height: rem(14);

					&:not(:hover) * {
						color: var(--redesigned-color-label-secondary);
					}
				}
			}
		}
	}

	.wallet {
		align-items: flex-start;
		padding: rem(20);

		.estimated {
			& > div:first-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(4);

				span {
					@include font(12, 500, 16);
					color: var(--redesigned-color-label-secondary);
				}

				button:not(:hover) i {
					color: var(--redesigned-color-label-secondary);
				}
			}

			& > div:last-child {
				@include font(32, 500, 40);
			}
		}
	}

	.asset {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: auto;
		padding: rem(20);
		margin-bottom: rem(12);
		background-color: var(--redesigned-color-background-base-3);

		@include hover {
			background-color: var(--redesigned-color-background-base-1);
		}

		.caption {
			width: 40%;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rem(28);

			img {
				width: rem(32);
				height: rem(32);
				padding: rem(8);
				border-radius: 50%;
				background-color: var(--redesigned-color-background-base-1);
			}

			span {
				@include font(16, 500, 20);
			}
		}

		.balance {
			flex-grow: 1;

			& > div:first-child {
				@include font(16, 500, 20);
				margin-bottom: rem(2);
			}

			& > div:last-child {
				@include font(12, 400, 16);
				color: var(--redesigned-color-label-secondary);
			}
		}

		&:has(.soon) {
			opacity: 1;
			border: 0;
		}

		.soon {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.7);
		}
	}
}
