@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.billboard {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: rem(8) rem(20) rem(8) rem(60);
	border-radius: rem(12);
	background-color: var(--redesigned-color-accent-brown);
	background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%23FF8C00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-repeat: no-repeat;
	background-position: rem(20) center;
	background-size: rem(24);

	&.bordered {
		box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-accent-brown-light);
	}

	p,
	li {
		padding: 0;
		margin: 0;
		font-size: rem(14);
		font-weight: 400;
		line-height: rem(20);
		color: var(--redesigned-color-text-primary);
	}

	ol {
		margin: 0;
		padding: 0;
		padding-left: rem(18);
		display: flex;
		flex-direction: column;
		gap: rem(4);
	}
}
