@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.button {
	//.full
	--button-height: #{rem(52)};
	--button-padding: #{rem(24)};
	--button-icon-size: #{rem(20)};
	--button-font-size: #{rem(16)};
	--button-radius: #{rem(12)};
	--button-border: 0;
	--button-loader: #{rem(28)};

	//.primary
	--button-label-color: var(--redesigned-color-text-primary);
	--button-border-color: transparent;
	--button-back-color: var(--redesigned-color-accent-purple);
	--button-hover-label-color: var(--button-label-color);
	--button-hover-border-color: var(--button-border-color);
	--button-hover-back-color: var(--button-back-color);

	&.middle {
		--button-height: #{rem(40)};
		--button-padding: #{rem(24)};
		--button-icon-size: #{rem(16)};
		--button-font-size: #{rem(14)};
		--button-radius: #{rem(12)};
		--button-loader: #{rem(24)};
	}
	&.mini {
		--button-height: #{rem(32)};
		--button-padding: #{rem(12)};
		--button-icon-size: #{rem(16)};
		--button-font-size: #{rem(14)};
		--button-radius: #{rem(8)};
		--button-loader: #{rem(20)};
	}

	&.secondary {
		--button-border: #{rem(1)};
		--button-border-color: transparent;
		--button-back-color: var(--redesigned-color-background-base-1);
		--button-hover-label-color: var(--button-label-color);
		--button-hover-border-color: var(--redesigned-color-separator-opaque);
		--button-hover-back-color: var(--button-back-color);
	}
	&.outlined {
		--button-border: #{rem(1)};
		--button-border-color: var(--redesigned-color-separator-opaque);
		--button-back-color: transparent;
		--button-hover-label-color: var(--button-label-color);
		--button-hover-border-color: var(--button-border-color);
		--button-hover-back-color: var(--button-back-color);
	}
	&.clear {
		--button-border: 0;
		--button-border-color: transparent;
		--button-back-color: transparent;
		--button-hover-label-color: var(--redesigned-color-accent-purple);
		--button-hover-border-color: var(--button-border-color);
		--button-hover-back-color: var(--button-back-color);
	}

	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: rem(4);
	height: var(--button-height);
	padding: 0 var(--button-padding);
	outline: 0;
	border-radius: var(--button-radius);
	border: var(--button-border) solid var(--button-border-color);
	background: var(--button-back-color);
	transition: 0.2s;

	&.primary {
		box-shadow: inset 0 0 0 var(--button-height) transparent;
	}

	&.loading,
	&.disabled {
		pointer-events: none;
		cursor: not-allowed;
	}

	&.disabled {
		opacity: 0.32;
	}

	&.loading {
		position: relative;

		& > *:not(svg:last-child) {
			opacity: 0;
		}

		svg:last-child {
			position: absolute;
			left: calc(50% - var(--button-loader) / 2);
			top: calc(50% - var(--button-loader) / 2);
			width: var(--button-loader);
			height: var(--button-loader);
		}
	}

	* {
		color: var(--button-label-color);
	}

	& > span {
		white-space: nowrap;
		font-size: var(--button-font-size);
		font-weight: 500;
		text-decoration: none;
		transition: 0.2s;
	}

	& > i {
		font-size: var(--button-icon-size);
		transition: 0.2s;
	}

	& > img {
		width: var(--button-icon-size);
		height: var(--button-icon-size);
		object-fit: contain;
	}

	&:not(:has(span)) {
		padding: 0;
		aspect-ratio: 1;
	}

	@include hover {
		border-color: var(--button-hover-border-color);
		background-color: var(--button-hover-back-color);

		* {
			color: var(--button-hover-label-color);
		}

		&.primary {
			box-shadow: inset 0 0 0 var(--button-height) rgba(255, 255, 255, 0.12);
		}
	}

	&:active {
		box-shadow: inset 0 0 0 rem(1) var(--button-hover-border-color);

		&.primary {
			box-shadow: inset 0 0 0 var(--button-height) rgba(255, 255, 255, 0.24);
		}
	}
}
