.migrationNotification {
  --radius: 8px;
  --padding: 16px;
  --header-font: 24px;
  --text-font: 16px;

  display: flex;
  flex-direction: column;
  gap: var(--padding);
  padding: var(--padding);
  max-width: 400px;
  color: var(--color-text-primary);
  border-radius: var(--radius);
  background-color: var(--main-background-color);

  p {
    font-size: var(--text-font);
    font-weight: 400;
    margin: 0 0 var(--padding);
  }

  .buttonGroup {
    display: flex;
    gap: 8px;
  }

  button {
    margin: 0 auto;
    min-width: 150px;
  }
}
