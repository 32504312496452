@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.menuSections {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(4);

    & > label {
      font-size: rem(12);
      font-weight: 500;
      line-height: rem(16);
      margin-bottom: rem(4);
      padding: 0 rem(12);
      text-transform: uppercase;
      color: var(--redesigned-color-label-secondary);
    }

    &:not(:last-child) {
      padding-bottom: rem(16);
      border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);
    }
  }
}
