@import "assets/styles/common/mixins";

.container {
	@include flexbox(center, center, column);
	position: relative;
	margin: 0 auto;
}

.custom_text_button {
	@include flexbox(center, center);
	background-color: transparent;
	background: unset;
	height: unset;
	text-transform: none;
	background: none;
	border: unset;
	outline: none;
	width: unset;
	color: #7c59ff;
	font-size: 1em;
	font-weight: 500;
	margin-left: 4px;

	&:hover,
	&:active,
	&:focus {
		background: unset;

		i {
			color: var(--color-blue);
		}
	}

	&.fullwidth {
		width: 100%;
	}
}

.input_container {
	@include flexbox(stretch, center);
	margin-top: 32px;

	div[class~="otp-container"] {
		display: flex;
		flex-direction: row;
		flex: 1;
		gap: 10px;

		div {
			flex: 1;
		}
	}

	input {
		height: 52px;
		max-width: 63px;
		border-radius: 16px;
		border: 1px solid rgba(198, 198, 200, 0.2);
		background: #f2f2f7;
		box-sizing: border-box;
		padding: 3px 5px;
		outline: none;
		text-align: center;
		font-size: 24px;
		margin: 0;
		transition: border-color 200ms ease-in-out;
		color: #000000;

		&:focus {
			border-color: #7c59ff;
		}

		&:hover:not(:focus) {
			border-color: rgba(198, 198, 200, 0.36);
		}

		&[class~="otp-error"] {
			border-color: var(--input-error);

			&:focus {
				border-color: #7c59ff;
			}
		}
	}

	&.disabled {
		color: var(--input-label-disabled);

		input[class~="otp-error"] {
			border-color: var(--input-label-disabled);
			pointer-events: none;
		}
	}

	&.active {
		input {
			border-color: rgba(198, 198, 200, 0.36) !important;
		}
	}
}

.tooltip {
	max-width: 250px;
	padding: 5px;
}

.label_input {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	margin: 0 auto;
	min-width: 338px;

	&.maxWidth {
		max-width: 416px;
	}
}

.label {
	@include flexbox(flex-start, center);
	font-size: 14px;
	line-height: 14px;
	color: var(--color-secondary);
	margin-bottom: 10px;
	width: 100%;
	position: relative;

	> span {
		@include flexbox(flex-start, center);
	}

	i {
		font-size: 16px;
		margin-left: 8px;
		cursor: pointer;
	}

	@include max-w(md) {
		justify-content: space-between;
		font-size: 12px;
		line-height: 14px;
		width: 100%;
	}
}

.token_type_img {
	margin-right: 10px;
	width: 30px;
	min-width: 30px;
	height: 30px;
	max-width: 30px;
	max-height: 30px;
}

.error_text {
	line-height: 14px;
	font-size: 12px;
	width: 100%;
	border: 1px solid var(--input-error);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 15px;
	color: var(--input-error);

	&.maxWidth {
		max-width: 416px;
	}

	i {
		font-size: 12px;
		color: var(--input-error);
		margin: 1px 7px 0 0;
	}

	@include max-w(md) {
		font-size: 11px;
	}
}

.help_text {
	@include flexbox(center, center);
	width: 100%;
	margin-top: 1em;
	max-width: 100%;
	text-align: center;
	word-break: break-word;
	color: rgba(60, 60, 67, 0.6);
	font-size: 1em;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.0025em;

	&.resend {
		padding: 0;
	}
}

.full_size_text {
	font-style: normal;
	font-weight: normal;
	max-width: 384px;
	width: 100%;
	margin: 0 auto 32px;
	color: #1c1c1c;
	text-align: center;
	font-size: 14px;
	line-height: 20px;
}

.additional_text {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	color: var(--color-secondary);
	max-width: 384px;
	width: 100%;
	margin: -10px auto 20px;
}

.full_size_image {
	width: 140px;
	height: auto;
	max-width: 140px;
	max-height: 140px;
	margin: 0 auto 30px;
}

.spinner_container {
	@include flexbox(center, center);
	height: 40px;
	width: 100%;
	margin: 20px 0;
}

.button_container {
	@include flexbox(center, center, column);
	gap: 8px;
	width: 100%;
	margin-top: 4em;
}

.button_back {
}
