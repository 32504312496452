@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.progressButton {
	padding: rem(20);
	border-radius: rem(16);
	border: rem(1) solid transparent;
	color: var(--redesigned-color-text-primary);
	background-color: var(--redesigned-color-background-base-3);
	transition: 0.2s;
	cursor: default;

	&.clickable {
		cursor: pointer;

		@include hover {
			border-color: var(--redesigned-color-separator-opaque);
		}

		&:active {
			border-color: var(--redesigned-color-separator-opaque);
			box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
		}
	}

	&:disabled {
		opacity: 0.32;
		pointer-events: none;
	}

	.title {
		display: flex;
		flex-direction: row;
		align-items: center;

		span {
			flex-grow: 1;
			text-align: left;
			font-size: rem(16);
			font-weight: 500;
			line-height: rem(20);
		}
	}

	.bar {
		height: rem(12);
		margin: rem(16) 0 rem(8);
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rem(3);

		hr {
			padding: 0;
			margin: rem(2) 0;
			width: 0;
			border-radius: rem(1);
			border: rem(1) solid var(--redesigned-color-text-primary);
		}

		div {
			position: relative;
			border-radius: rem(4);
			background-image: linear-gradient(105deg,
				transparent 30%,
				rgba(0, 0, 0, 0.08) 30%,
				rgba(0, 0, 0, 0.08) 70%,
				transparent 70%
			);
			background-size: rem(16) 100%;
			transition: 0.5s ease-in-out;
			overflow: hidden;
		}

		div:first-child::before,
		div:last-child::after {
			content: "";
			position: absolute;
			top: 0;
			height: 100%;
			max-width: 100%;
			aspect-ratio: 1;
			border-radius: rem(4);
			border: rem(2) solid transparent;
			background-color: var(--redesigned-color-background-base-1);
			background-clip: content-box;
			z-index: 1;
		}

		div:first-child {
			background-color: var(--redesigned-color-accent-purple);
			background-position: left;

			&::before {
				left: 0;
			}
		}

		div:last-child {
			background-color: var(--redesigned-color-background-tertiary);
			background-position: right;

			&::after {
				right: 0;
			}
		}
	}

	.labels {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		span {
			font-size: rem(12);
			font-weight: 500;
			line-height: rem(16);

			&:last-child {
				color: var(--redesigned-color-label-tertiary);
			}
		}
	}
}
