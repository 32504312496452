@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.stepper {
	position: relative;
	padding: rem(20);
	border-radius: rem(16);
	background-color: var(--redesigned-color-background-base-3);

	.item {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rem(12);

		& > div:first-child {
			display: flex;
			flex-direction: column;
			align-items: center;

			& > div {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: rem(24);
				aspect-ratio: 1;
				border-radius: 50%;
				border: rem(1) solid var(--redesigned-color-separator-opaque);
			}

			&:after {
				content: "";
				flex-grow: 1;
				min-height: rem(20);
				display: block;
				border-left: rem(1) dashed var(--redesigned-color-separator-opaque);
			}
		}

		& > div:last-child {
			flex-grow: 1;
		}

		&.passed > div:first-child > div {
			border: 0;
			background-color: var(--redesigned-color-accent-purple);

			i {
				font-size: rem(11);
				color: var(--redesigned-color-text-primary);
			}
		}

		&.active > div:first-child > div {
			border: rem(4) solid var(--redesigned-color-accent-purple);
		}

		&.last > div:first-child:after {
			content: none;
		}

		.title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: rem(8);
			font-size: rem(16);
			font-weight: 500;
			line-height: rem(20);
			padding: rem(2) 0;
			color: var(--redesigned-color-text-primary);
		}

		.content {
			padding: rem(12) 0 rem(20);
		}

		&:last-child .content {
			padding-bottom: 0;
		}

		&:not(.active):not(.passed) .content {
			display: none;
		}
	}

	&:has(.footer:not(:first-child)) > div:nth-last-child(2) {
		min-height: rem(44);
	}

	.footer {
		margin-left: rem(32);

		&:not(:first-child) {
			padding-top: rem(20);
			border-top: rem(1) solid var(--redesigned-color-separator-opaque);
		}
	}
}
