@import "src/assets/styles/common/mixins";

.wrapper {
	cursor: pointer;
	position: relative;
	width: 100%;
}

.option_content {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	color: #000;
	font-size: 16px;

	&__selected {
		color: #7c59ff;
	}

	img {
		height: 20px;
		width: 25px;
	}

	&.value {
		color: #000;
		font-size: 16px;
	}
}

.option_check {
	margin-left: auto;
	width: 20px;
	height: 20px;
}

.single_value_container {
	@include flexbox(stretch, center);

	width: 100%;
}

.single_value {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.placeholder {
	left: 85px;
	background: var(--card-background-color);
	font-size: 14px;
	top: 12px;
	padding: 0;
	z-index: 3;

	&.active,
	&.filled {
		font-size: 12px;
		top: -10px;
		padding: 0 3px;
		left: 80px !important;
	}

	@include max-w(md) {
		&.active,
		&.filled {
			display: none;
		}
	}
}

.placeholder_comp_container {
	height: 100% !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	position: relative !important;
	padding-right: 0 !important;
	padding-left: 0 !important;

	&.error {
		i {
			color: var(--input-error);
		}

		.img_block:before {
			background-color: var(--input-error);
		}
	}
}

.placeholder_comp {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.img_block {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	width: 16.6px;
	height: 16.6px;
	border-radius: 100%;
	overflow: hidden;

	img {
		background-size: cover;
	}

	i[class~="ai-arrow_down"],
	i[class~="ai-arrow_up"] {
		font-size: 8px;
		line-height: 8px;
	}

	i[class~="ai-arrow_up"] {
		color: #4a4a4a;
	}
}

.empty_flag {
	margin-right: 10px;
}

.value_container {
	-webkit-box-align: center !important;
	align-items: center !important;
	display: flex !important;
	flex: 1 1 0 !important;
	flex-wrap: wrap !important;
	padding: 0 24px !important;
	position: relative !important;
	overflow: hidden !important;
	box-sizing: border-box !important;

	i[class~="ai-web_outlined"] {
		font-size: 16.6px;
	}
}

.clear_indicator {
	margin-left: auto;

	i {
		font-size: 14px;
	}
}
