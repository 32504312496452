@import "../redesigned/functions";
@import "../redesigned/media";

$transition: 0.3s;

.modalWrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	opacity: 0;
	visibility: hidden;
	transition: $transition;
	background-color: rgb(0 0 0 / 70%);
	z-index: 99999;

	& > :first-child {
		margin: auto;
		max-height: 100%;
		transform: translateY(#{rem(100)});
		transition: transform $transition;
	}

	&.opened {
		opacity: 1;
		visibility: visible;

		& > :first-child {
			transform: translateX(0) !important;
		}
	}

	.modal {
		--radius: #{rem(8)};
		--padding: #{rem(20)};
		--header-font: #{rem(20)};
		--close-button: 32px;
		--close-button-radius: 8px;
		--close-button-icon: 16px;

		width: var(--width);
		max-width: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		overflow-y: auto;
		scrollbar-width: auto;
		scrollbar-gutter: stable;
		border-radius: var(--radius);
		border: rem(1) solid var(--redesigned-color-separator-opaque);
		color: var(--redesigned-color-text-primary);
		background-color: var(--redesigned-color-background-base-3);

		.header {
			top: 0;
			position: sticky;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: var(--padding);
			border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);
			backdrop-filter: blur(#{rem(20)});
			z-index: 10;

			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0.5;
				z-index: -1;
				background-color: var(--redesigned-color-background-base-3);
			}

			& > span {
				flex-grow: 1;
				font-size: var(--header-font);
				font-weight: 500;
				padding-right: calc(var(--close-button) + var(--padding));
			}

			& > button {
				align-self: flex-start;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: calc(var(--close-button) * -1);
				width: var(--close-button);
				height: var(--close-button);
				border-radius: var(--close-button-radius);
				border: rem(1) solid transparent;
				transition: border-color 0.2s;

				@include hover {
					border-color: var(--redesigned-color-separator-opaque);
				}

				svg {
					width: var(--close-button-icon);
					height: var(--close-button-icon-button);

					path {
						stroke: var(--redesigned-color-text-primary);
					}
				}
			}
		}

		.content {
			padding: var(--padding);
		}
	}
}
