@import "src/assets/styles/common/mixins";

.container {
	@include flexbox(flex-start, flex-start, column);
	position: relative;
	width: 100%;
	background-color: #101014;
}

.header {
	@include flexbox(space-between, flex-end);
	padding: 30px 24px;
	background-color: #101014;
	position: relative;
	width: 100%;
	margin: 0 0 2px 0;

	@include max-w(lg) {
		@include flexbox(flex-start, flex-start, column);

		padding: 24px;
		height: auto;
	}

	h1 {
		font-weight: bold;
		font-size: 36px;
		line-height: 1.3;
		color: var(--color-primary);
		margin: 0;
	}

	@include max-w(md) {
		border-color: transparent;
	}

	@include max-w(xs) {
		margin: 0;
		border-radius: 0;
		border: none;
	}
}

.tabs {
	height: 40px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.table_row {
	padding: 0 30px;
}

.closed_orders_row {
	height: 39px;
	max-height: 39px;
	padding: 0 30px;
}

.orders_history_opened_orders_row_date {
	display: flex;
	flex-wrap: wrap;
}

.expand_icon {
	cursor: pointer;
	font-size: 10px;
	transition: 0.15s ease-out;
	transform: rotate(0);
	color: var(--icon-primary);

	&:hover {
		color: var(--icon-pressed);
	}

	&.active {
		color: var(--icon-pressed);
	}
}
