@import "src/styles/mixins";

.mobileBurger {
	display: none;

	@media screen and (max-width: 1024px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__depositButton {
		@include home-page-main-button;

		padding: 7px 16px;
		font-size: 14px;
		font-weight: 500;
		line-height: 160%;
		max-height: 36px;
		margin-right: 8px;
	}

	&__profile {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 4px;
	}

	&__profileCheckbox {
		display: none;

		&:checked + .mobileBurger__profileButton + .mobileBurger__profileContent {
			opacity: 1;
			pointer-events: auto;

			.mobileBurger__closeArea {
				opacity: 1;
			}

			.mobileBurger__burgerContentWrapper {
				right: 0;
			}
		}
	}

	&__profileButton {
		height: 36px;
		width: 36px;
	}

	&__burgerCheckbox {
		display: none;

		&:checked + .mobileBurger__burgerButton + .mobileBurger__burgerContent {
			opacity: 1;
			pointer-events: auto;

			.mobileBurger__closeArea {
				opacity: 1;
			}

			.mobileBurger__burgerContentWrapper {
				right: 0;
			}
		}
	}

	&__burgerButton {
		height: 36px;
		width: 36px;
		cursor: pointer;
	}

	&__profileContainer {
		padding: 16px 16px 162px 16px;
		height: calc(100vh - 63px);
		overflow: auto;
	}

	&__burgerContent,
	&__profileContent {
		display: flex;
		position: fixed;
		right: 0;
		bottom: 0;
		top: 0;
		left: 0;
		z-index: 10;
		opacity: 0;
		transition: 0.3s ease-in-out;
		pointer-events: none;
	}

	&__closeArea {
		flex: 1;
		background-color: rgba(16, 16, 20, 0.4);
		opacity: 0;
		transition: 0.3s ease-in-out;
	}

	&__burgerContentWrapper {
		position: fixed;
		top: 0;
		right: -375px;
		bottom: 0;
		width: 375px;
		background-color: var(--main-background-color);
		transition: 0.3s ease-in-out;

		@media screen and (max-width: 549px) {
			width: 100vw;
			right: -100%;
		}
	}

	&__header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 20px;
		height: 47px;
		border-bottom: 1px solid var(--header-separator-color);

		@media screen and (max-width: 1024px) {
			height: 64px;
		}

		@media screen and (max-width: 549px) {
			justify-content: space-between;
		}
	}

	&__headerLogo {
		display: none;

		@media screen and (max-width: 549px) {
			display: flex;
		}
	}

	&__content {
		padding: 12px 16px 0 16px;
	}

	&__auth {
		display: flex;
		grid-gap: 12px;
	}

	&__signInButton {
		@include home-page-main-button;

		flex: 1;
		height: 44px;
		font-size: 16px;
		font-weight: 500;
		background-color: #261f43;
		color: #7c59ff;
	}

	&__signUpButton {
		@include home-page-main-button;

		flex: 1;
		height: 44px;
		font-size: 16px;
		font-weight: 500;
	}

	&__menuList {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		flex: 1;
		height: calc(100vh - 120px);
		overflow: auto;
		padding-bottom: 162px;
		box-sizing: border-box;

		&___authenticated {
			margin-top: 16px;
			height: calc(100vh - 189px);
		}
	}

	&__menuItem {
		display: flex;
		padding: 16px 12px 16px 0;
		align-items: center;
		justify-content: space-between;
	}

	&__menuIcon {
		i {
			font-size: 32px;
			color: #7c59ff;
		}
	}

	&__menuName {
		color: #fff;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		margin-left: 12px;
	}

	&__apps {
		display: flex;
		gap: 16px;
		margin-top: 32px;
	}

	&__socials {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 16px;
		margin-top: 16px;

		i {
			color: #fff;
			font-size: 32px;
		}
	}

	&__appLink {
		display: flex;
		flex: 1;

		img {
			width: 100%;
			border: 1px solid #fff;
			border-radius: 12px;
		}
	}

	&__address {
		margin-top: 16px;
		font-size: 16px;
		color: #fff;
	}
}
