@import "./variables";

@import "assets/styles/common/mixins";
@import "assets/styles/common/animations";
@import "assets/styles/common/slick";
@import "assets/styles/common/react-select";
@import "assets/styles/common/react-date-range";
@import "assets/styles/common/react-date-range-theme-default";
@import "assets/styles/common/toastify";
@import "assets/styles/common/circle_progress";

:root {
	// NEW DESIGN SCHEMA

	/* SYSTEM COLORS */
	--color-white: #fff;
	--color-black: #000;
	--color-red: #ff3b30;
	--color-green: #34c759;
	--color-blue: #007aff;
	--color-yellow: #ffcc00;
	--color-grey-1: #8e8e93;
	--color-grey-2: #aeaeb2;
	--color-grey-3: #c7c7cc;
	--color-grey-4: #d1d1d6;
	--color-grey-5: #e5e5ea;
	--color-grey-6: #f2f2f7;

	/* BACKGROUND COLORS */
	--color-background-primary: #f2f2f7;
	--color-background-secondary: #f2f2f7;
	--color-background-tertiary: #ffffff;

	/* FILL COLORS */
	--color-fill-primary: #78788033;
	--color-fill-secondary: #78788029;
	--color-fill-tertiary: #7676801f;
	--color-fill-quarternary: #76768014;

	/* TEXT COLORS */
	--color-text-primary: #000000;
	--color-text-secondary: #1c1c1c;
	--color-text-tertiary: #3c3c4399;
	--color-text-quarternary: #3c3c434d;
	--color-text-special: #3c3c432e;

	/* SEPARATOR COLORS */
	--color-separator: #c6c6c8;
	--color-separator-opaque: #3c3c435c;

	// OLD DESIGN SCHEMA
	--background-color: #f9f9f9;
	--background-gray: #f9f9f9;
	--background-color-transparent: #fafafa00;
	--background-gray-opaque: #dedede40;
	--background-blue-alt: rgba(0, 122, 255, 0.25);
	--background-blue-opaque: rgba(77, 143, 241, 0.1);
	--color-primary: #030303;
	--color-secondary: #696969;
	--color-secondary-darK: #787878;
	--color-disabled: #aaaaaa;
	--color-disabled-hover: #aaaaaa1c;
	--color-coral: #ff542e;
	--color-orange-alt: #f79319;
	--background-orange-alt-opaque: rgba(247, 147, 25, 0.1);
	--color-gold: #e8b104;
	--color-gold-dark: #df8430;
	--color-gray: #e6e6e5;
	--color-grey-secondary: #9e9e9e;
	--color-demo: #f79319;
	--color-red-hover: #ff5f661c;
	--color-orange: #f79319;
	--color-purple: #9b51e0;
	--color-blue: #0a84ff;
	--color-light-blue: #4d8ff1;
	--color-blue-hover: #007aff1c;
	--color-green-hover: #00c8531c;
	--color-yellow-dark: #f79e1b;
	--color-green-light: #21c177;
	--color-promo-gold: #f8a15d;
	--color-caret: #000;
	--color-focus: #75b7ff;
	--skeleton-base-color: #f3f3f3;
	--skeleton-highlight-color: #f9f9f9;
	--header-border-color: #e3e3e3;
	--active-tab-color: black;

	--thumb-color: rgb(200, 200, 200);

	// NAVMENU
	--navmenu-background: #fff;
	--navmenu-box-shadow: 0px 3px 8px rgb(207 207 207 / 50%);
	--navmenu-item-hover: #f9f9f9;

	// NEWS
	--news-color-primary: #007aff;

	// STORIES
	--stories-color-primary: #ea5045;
	--stories-color-primary-opaque: #ea50451a;
	--stories-empty-image-background: #c4c4c4;
	--stories-simplified-list-item-background: #f9f9f9;
	--stories-more-icon-color: #dedede;
	--stories-share-title-color: #c4c4c4;
	--stories-post-box-shadow: 0px 0px 24px rgb(176 190 197 / 50%);

	// COMMON
	--dropzone-preview-background: #e6e6e6;
	--ticket-path-out-color: rgba(158, 158, 158, 0.3);
	--divider-border: #e5e5e5;
	--badge-color: #ffffff;
	--loader-color: #c4c4c4;
	--badge-a-dark: #4a4a4a1c;
	--badge-a-dark-color: #4a4a4a;

	// COOKIES ACCEPT
	--cookies-accept-color: #fff;
	--cookies-accept-background: rgba(37, 37, 37, 0.75);

	// CONTENT
	--content-blur: linear-gradient(
		275.48deg,
		rgba(255, 255, 255, 0.8) 0%,
		rgba(255, 255, 255, 0.4) 100%
	);

	// FINANCE METHOD
	--finance-method-border-color: #c4c4c4;
	--finance-method-border-color-disabled: #d8d8d8;
	--finance-method-border-color-hover: #727272;
	--finance-method-background-color: #fff;
	--finance-method-img-filter: grayscale(1);

	// TABLE
	--table-hover-background: #eff7ff;
	--table-table-filter: #6d7177;
	--table-header-border-color: #e5e5e5;
	--table-active-row-background: #e8f0fb;
	--table-stripped-row-background: #fbfbfb;
	--table-stripped-border-color: #dedede;
	--table-expand-table-row-shadow: 0px 0px 9px rgba(176, 190, 197, 0.75);
	--table-total-value-background-color: rgba(207, 231, 255, 0.25);
	--table-disabled-cell-badge-background: #7878781a;
	--table-stripped-loading: rgba(249, 249, 249, 0.45);
	--table-no-data-icon-color: #c4c4c4;
	--table-info-box-background: #f0f0f0;
	// NEW TABLE
	--table-background-color: #f3f5f9;
	--table-separator-color: rgba(243, 245, 249, 0.5);

	// TAB BUTTON
	--tab-button-backround: rgba(196, 196, 196, 0.15);
	--tab-button-background-hover: rgba(0, 122, 255, 0.06);
	--tab-button-color: #007aff;

	// HISTORY ORDERS
	--history-orders-overlay-background-color: #e3e3e3;
	--history-orders-overlay-time-filter-button-background: #dbdbdb;
	--history-orders-overlay-button-border: 1px solid #c8c7c7;

	// ICON
	--icon-disabled: #dedede;
	--icon-primary: #5d5f63;
	--icon-secondary: #545454;
	--icon-pressed: #4a4a4a;
	--icon-active: #007aff;

	// INFO SNACK
	--info-snack-color-yellow: #f79319;
	--info-snack-color-yellow-a: #f793191f;
	--info-snack-color-red: #ff5f66;
	--info-snack-color-red-a: #ff5f661f;
	--info-snack-color-grey: #696969;
	--info-snack-color-grey-a: #6969691f;
	--info-snack-color-primary: #030303;
	--info-snack-color-primary-a: #0303031f;
	--info-snack-color-border-yellow: #f79319;
	--info-snack-color-border-yellow-a: #f793191f;
	--info-snack-color-border-red: #ff5f66;
	--info-snack-color-border-red-a: #ff5f661f;
	--info-snack-color-border-grey: #c4c4c4;
	--info-snack-color-border-grey-a: #c4c4c41f;
	--info-snack-color-border-primary: #007aff;
	--info-snack-color-border-primary-a: #007aff1f;

	// CHIP
	--chip-background: #505050;
	--chip-disabled: #eeeeee;
	--chip-color: #f2f2f2;

	// ACCORDION
	--accordion-header-background-hover: #f0f2fa;
	--accordion-content-background: #eff7ff;
	--accordion-content-background-hover: #e1edff;

	// SWITCH
	--switch-button-disabled: #fefbff;
	--switch-button-normal: #696969;
	--switch-label-normal: #ececec;
	--switch-label-disabled: #ababab;
	--switch-label-checked: #007aff;
	--switch-button-active: #d8e2ff;

	// HINT ICON
	--hint-icon-normal: #dedede;
	--hint-icon-pressed: #4a4a4a;
	--hint-icon-dark: #c4c4c4;
	--hint-icon-dark-pressed: #787878;
	--hint-background: #4a4a4a;

	// MODAL
	--modal-overlay-color: rgba(0, 0, 0, 0.45);
	--modal-content-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
	--modal-info-group-background: #f9f9f9;

	// ICON BUTTON
	--icon-button-background-color: #fff;
	--icon-button-background-color-hover: #007aff;
	--icon-button-border-color: #6d7177;
	--icon-button-border-color-hover: #007aff;
	--icon-button-color: #6d7177;
	--icon-button-color-hover: #fff;

	// TOAST
	--toast-close-icon-color: rgba(255, 255, 255);
	--toast-close-icon-color-hover: rgba(255, 255, 255, 0.6);

	// STRIPPED
	--stripped-blue: #c6e4fc;
	--stripped-green: #00c853;
	--stripped-normal: #f9f9f9;

	// CHART
	--chart-green: #00b172;
	--chart-red: #ff5f66;
	--chart-volume-green: rgba(0, 177, 114, 0.2);
	--chart-volume-red: rgba(255, 95, 102, 0.2);
	--chart-grid-color: #e5e5e5;

	// ORDERBOOK
	--orderbook-red: #ffeef1;
	--orderbook-green: #e5fdeb;
	--orderbook-red-primary: #ff5f66;
	--orderbook-red-secondary: rgba(255, 52, 76, 0.6);
	--orderbook-green-primary: #00b761;
	--orderbook-green-secondary: rgba(0, 182, 95, 0.55);
	--orderbook-hover-background: rgba(207, 231, 255, 0.17);
	--orderbook-active-red: #ff453a;
	--orderbook-active-green: #32d74b;
	--orderbook-option-green: #32d74b;
	--orderbook-option-red: #ff453a;
	--orderbook-option-red-alpha: #ffa6a6;
	--orderbook-option-green-alpha: #53b987;
	--orderbook-param-name: #abb4bd;

	// CARD
	--card-background-color: #ffffff;
	--card-background-color-secondary: #eee;
	--card-background-color-alt: #fcfcfc;
	--card-header-background: #f5f5f5;
	--card-header-secondary: #ffffff;
	--card-border-color: #e5e5e5;
	--card-shadow: 0px 1px 0px #787878;
	--card-outline-secondary: #e5e5e5;
	--card-outlined-disabled: #e5e5e5;
	--card-outlined-border-color: #d5d5d5;

	// INPUT
	--input-normal: #c4c4c4;
	--input-hover: #727272;
	--input-focused: #007aff;
	--input-error: #ff5f66;
	--input-disabled: #d8d8d8;
	--input-background: #eeeef0;
	--input-slider-thumb-background: #dedede;

	// INPUT-LABEL
	--input-label-normal: #787878;
	--input-label-disabled: #d8d8d8;
	--input-label-active: #007aff;

	// TOOLTIP
	--tooltip-icon-color: #4a4a4a;
	--tooltip-icon-color-hover: #d8d8d8;
	--tooltip-background: #d8d8d8;
	--tooltip-color: #4a4a4a;

	// BUTTON FILLED
	--button-filled-color: #ffffff;

	--button-filled-primary-background: #7c59ff;
	--button-filled-primary-background-hover: #896aff;
	--button-filled-primary-background-active: #7753ff;

	--button-filled-secondary-background: #ff542e;
	--button-filled-secondary-background-hover: #f93c13;
	--button-filled-secondary-background-active: #ef2c01;

	--button-filled-tertiary-background: #32d74b;
	--button-filled-tertiary-background-hover: #2ec244;
	--button-filled-tertiary-background-active: #27a337;

	--button-filled-quaternary-background: #f79319;
	--button-filled-quaternary-background-hover: #ef880a;
	--button-filled-quaternary-background-active: #e57e00;

	--button-filled-quinary-background: #ff5f66;
	--button-filled-quinary-background-hover: #f1454d;
	--button-filled-quinary-background-active: #ff6269;

	--button-filled-senary-background: #786edf;
	--button-filled-senary-background-hover: #5d52ca;
	--button-filled-senary-background-active: #5d52ca;

	--button-filled-darkness-background: rgba(196, 196, 196, 0.15);
	--button-filled-darkness-background-hover: rgba(0, 122, 255, 0.08);
	--button-filled-darkness-background-active: rgba(0, 122, 255, 0.08);
	--button-text-darkness-background: #007aff;

	--button-filled-disabled-color: #979797;
	--button-filled-disabled-background: #c7c7cc;

	// BUTTON TEXT
	--button-text-background: transparent;
	--button-text-background-hover: transparent;
	--button-text-background-active: transparent;

	--button-text-primary-color: #7c59ff;
	--button-text-secondary-color: #ff542e;
	--button-text-tertiary-color: #21c177;
	--button-text-quaternary-color: #f79319;
	--button-text-quinary-color: #ff5f66;
	--button-text-senary-color: #5d52ca;

	--button-text-disabled-color: #979797;
	--button-text-disabled-background: transparent;

	// DROPDOWN MENU
	--dropdown-menu-item-background-hover: #f9f9f9;
	--dropdown-menu-item-background-color: #fff;

	// SELECT
	--select-shadow: 0px 0px 9px rgba(176, 190, 197, 0.5);

	// CUSTOM SEARCH INPUT
	--custom-search-input-background: #ffffff;

	// FLAT BUTTON
	--button-flat-disabled: #fff;

	// PROGRESS
	--progress-background-color: #dedede;
	--progress-fill-color: #ff542e;
	--progress-canceled-fill-color: #bababa;

	--terminal-scroll-track: #f5f5f5;
	--terminal-scroll-thumb: #ccc;

	// PROMO
	--promo-link-button: #ebf5ff;

	// Slider
	--slider-background: white;

	// Transition
	--transition-type: ease;
	--transition-time: 125ms;
	--transition-time-long: 600ms;

	// Comments

	// Icons
	--search-icon-image: url("/assets/icons/search-light.svg");

	// COIN
	--coin-detail-text: #464646;
	--coin-link: #e9e9e9;

	// HomePage
	--input-border-color: #c5cbe1;
	--input-border-color--hover: #9d82ff;
	--input-border-color--focus: #7c59ff;
	--input-background-color: #fff;
	--input-text-color: #2e2b26;
	--input-text-color--placeholder: #8b909e;
	--headline-home-page: #2e2b26;
	--content-text-home-page: #2e2b26;
	--arrow-button-background-color-home-page: #fff;

	// banner
	--banner-headline-background: linear-gradient(180deg, #28083c 0%, rgba(43, 8, 60, 0.78) 100%);
	--banner-content-color: #3c4a47;
	--banner-slider-background-color: rgba(255, 255, 255, 0.7);
	--banner-slider-box-shadow-color: drop-shadow(0 14px 27px rgba(0, 0, 0, 0.1));
	--banner-slider-coin-name-color: #2e2b26;
	--banner-slider-headline-color: #2e2b26;
	--banner-slider-value-color: #2e2b26;
	--banner-slider-wave-opacity: 1;

	//benefits
	--benefits-nav-text-color: #2e2b26;
	--benefits-nav-list-border-color: rgba(186, 193, 214, 0.4);
	--benefits-nav-text-color--active: #7c59ff;
	--benefits-nav-border-color--active: #7c59ff;
	--benefits-nav-border-color: #7c59ff;
	--benefits-nav-svg-color--active: #7c59ff;
	--benefits-background-color: #fff;
	--benefits-border-color: rgba(186, 193, 214, 0.12);
	--benefits-headline-color: #2e2b26;
	--benefits-content-text-color: #3c4a47;
	--benefits-content-secondary-headline-color: #2e2b26;

	//Supports
	--support-background-color: #fff;
	--support-text-color: #2e2b26;
	--support-headline-color: #2e2b26;

	//USP
	--usp-background-color: #fff;
	--usp-text-color: #2e2b26;
	--usp-headline-color: #2e2b26;
	--usp-headline-background-color: rgba(124, 89, 255, 0.15);

	//Partners
	--partners-before-background: linear-gradient(-90deg, rgba(235, 238, 248, 0) 0%, #ebeef8 100%);
	--partners-after-background: linear-gradient(90deg, rgba(235, 238, 248, 0) 0%, #ebeef8 100%);
	--partners-item-border-color: #c5cbe1;

	//MobileApp
	--mobile-app-background: #fff;
	--mobile-app-item-text-color: #2e2b26;

	//Communities
	--communities-background: #fff;
	--communities-link-name-text-color: #2e2b26;
	--communities-link-count-text-color: #8b909e;
	--communities-link-count-text-color--hover: #7c59ff;

	//Footer
	--footer-background: #fff;
	--footer-svg-fill: #fff;
	--footer-column-headline-color: #2e2b26;
	--footer-column-copy-color: #8b909e;
	--footer-column-link-color: #8b909e;
	--footer-column-link-color--mobile: #2e2b26;
	--footer-column-link-color--hover: #7c59ff;
}

[data-theme="dark"] {
	// NEW DESIGN SCHEMA

	/* SYSTEM COLORS */
	--color-white: #fff;
	--color-black: #000;
	--color-red: #ff453a;
	--color-green: #32d74b;
	--color-blue: #7c59ff;
	--color-yellow: #ffd60a;
	--grey-1: #8e8e93;
	--grey-2: #636366;
	--grey-3: #48484a;
	--grey-4: #3a3a3c;
	--grey-5: #2c2c2e;
	--grey-6: #1c1c1e;

	/* BACKGROUND COLORS */
	--color-background-primary: #1c1c1e;
	--color-background-secondary: #2c2c2e;
	--color-background-tertiary: #3a3a3c;

	--main-background-color: #101014;
	//--main-background-color: #9b0000;
	--page-background-color: #000000;
	--popups-main-background-color: #2c2c2e;
	--popups-secondary-background-color: #3a3a3c;

	/* FILL COLORS */
	--color-fill-primary: #7878805c;
	--color-fill-secondary: #78788052;
	--color-fill-tertiary: #7676803d;
	--color-fill-quarternary: #7676802e;

	/* TEXT COLORS */
	--color-text-primary: #ffffff;
	--color-text-secondary: #e6e6e6;
	--color-text-tertiary: #ebebf599;
	--color-text-quarternary: #ebebf54d;
	--color-text-special: #ebebf52e;

	--color-text-header-secondary: rgba(235, 235, 245, 0.6);

	/* SEPARATOR COLORS */
	--color-separator: #38383a;
	//--color-separator-opaque: #5454585c;
	--color-separator-opaque: rgba(255, 239, 239, 0.4);

	--header-separator-color: #54545836;

	// OLD DESIGN SCHEMA
	//--background-color: #1a1c1f;
	// Icons
	--search-icon-image: url("/assets/icons/search-dark.svg");

	--background-color: #000000;
	--background-color-transparent: #20202000;
	--color-primary: #ffffff;
	--color-secondary: #a2a3a4;
	--color-disabled: #70777e;
	--color-secondary-darK: #787878;
	--color-caret: #fff;
	--color-gray: #3a3a3c;

	--skeleton-base-color: #23262b;
	--skeleton-highlight-color: #303339;
	--header-border-color: #2a2d32;
	--active-tab-color: white;
	--color-grey-secondary: #9e9e9e;

	--thumb-color: rgb(100, 100, 100);

	// NAVMENU
	--navmenu-background: #2c2c2e;
	--navmenu-box-shadow: none;
	--navmenu-item-hover: #1c1e22;

	// NEWS
	--news-color-primary: #ea5045;

	// STORIES
	--stories-color-primary: #7c59ff;
	--stories-empty-image-background: #4a4a4a;
	--stories-simplified-list-item-background: #222429;
	--stories-more-icon-color: #4a4a4a;
	--stories-share-title-color: #787878;
	--stories-post-box-shadow: 0px 0px 24px rgb(18 18 18 / 50%);

	// COMMON
	--dropzone-preview-background: #4d4d4d;
	--ticket-path-out-color: rgba(158, 158, 158, 0.3);
	--divider-border: #31343b;
	--badge-color: #0a0a0a;
	--loader-color: #4a4a4a;
	--badge-a-dark: #e3e3e330;
	--badge-a-dark-color: #949494;

	// COOKIES ACCEPT
	--cookies-accept-color: #0a0a0a;
	--cookies-accept-background: rgba(255, 255, 255, 0.75);

	// CONTENT
	--content-blur: linear-gradient(275.48deg, rgba(28, 30, 34, 0.95) 0%, rgba(28, 30, 34, 0.4) 100%);

	// FINANCE METHOD
	--finance-method-border-color: #606060;
	--finance-method-border-color-disabled: #4a4a4a;
	--finance-method-border-color-hover: #bababa;
	--finance-method-background-color: #1c1e22;
	--finance-method-img-filter: grayscale(1) opacity(0.3);

	// TABLE
	--table-hover-background: #222429;
	--table-table-filter: #6d7177;
	--table-header-border-color: #303236;
	--table-active-row-background: #222429;
	--table-stripped-row-background: #191b1f;
	--table-stripped-border-color: #4a4a4a;
	--table-expand-table-row-shadow: 0px 0px 11px rgba(0, 0, 0, 0.75);
	--table-total-value-background-color: rgba(21, 21, 21, 0.75);
	--table-disabled-cell-badge-background: #7878781a;
	--table-stripped-loading: rgba(30, 30, 30, 0.45);
	--table-no-data-icon-color: #c4c4c4;
	--table-info-box-background: #222429;

	// TAB BUTTON
	--tab-button-backround: rgba(196, 196, 196, 0.08);
	--tab-button-background-hover: rgba(0, 122, 255, 0.08);
	--tab-button-color: #f5f5f5;

	// HISTORY ORDERS OVERLAY
	--history-orders-overlay-background-color: #292929;
	--history-orders-overlay-time-filter-button-background: #333333;
	--history-orders-overlay-button-border: 1px solid #7e7e7e;

	// ICON
	--icon-disabled: #4a4a4a;
	--icon-primary: #959595;
	--icon-secondary: #6d7177;
	--icon-pressed: #f9f9f9;
	--icon-active: #007aff;

	// INFO SNACK
	--info-snack-color-yellow: #f79319;
	--info-snack-color-yellow-a: #f793191f;
	--info-snack-color-red: #ff5f66;
	--info-snack-color-red-a: #ff5f661f;
	--info-snack-color-grey: #b2b2b2;
	--info-snack-color-grey-a: #b2b2b21f;
	--info-snack-color-primary: #ffffff;
	--info-snack-color-primary-a: #ffffff;
	--info-snack-color-border-yellow: #f79319;
	--info-snack-color-border-yellow-a: #f793191f;
	--info-snack-color-border-red: #ff5f66;
	--info-snack-color-border-red-a: #ff5f66;
	--info-snack-color-border-grey: #787878;
	--info-snack-color-border-grey-a: #787878;
	--info-snack-color-border-primary: #007aff;
	--info-snack-color-border-primary-a: #007aff;

	// CHIP
	--chip-background: #363a43;
	--chip-disabled: #202225;

	// ACCORDION
	--accordion-content-background: #202328;
	--accordion-header-background-hover: #222429;
	--accordion-content-background-hover: #222429;

	// SWITCH
	--switch-button-disabled: #1c1b1f;
	--switch-button-normal: #a2a3a4;
	--switch-label-normal: #44474f;
	--switch-label-disabled: #5d5d60;
	--switch-label-checked: #adc6ff;
	--switch-button-active: #004493;

	// HINT ICON
	--hint-icon-normal: #4a4a4a;
	--hint-icon-pressed: #787878;
	--hint-icon-dark: #4a4a4a;
	--hint-icon-dark-pressed: #c4c4c4;
	--hint-background: #c4c4c4;

	// MODAL
	--modal-overlay-color: rgba(12, 12, 12, 0.8);
	--modal-content-shadow: 0px 0px 9px rgba(0, 0, 0, 0.7);
	--modal-info-group-background: #222429;

	// ICON BUTTON
	--icon-button-background-color: #1c1e22;
	--icon-button-background-color-hover: #007aff;
	--icon-button-border-color: #6d7177;
	--icon-button-border-color-hover: #007aff;
	--icon-button-color: #6d7177;
	--icon-button-color-hover: #fff;

	// TOAST
	--toast-close-icon-color: rgba(255, 255, 255);
	--toast-close-icon-color-hover: rgba(255, 255, 255, 0.6);

	// STRIPPED
	--stripped-blue: #4a4a4a;
	--stripped-green: #00c853;
	--stripped-normal: #2c2c2e;

	// CHART
	--chart-green: #00b172;
	--chart-red: #ff5f66;
	--chart-volume-green: rgba(0, 177, 114, 0.2);
	--chart-volume-red: rgba(255, 95, 102, 0.2);
	--chart-grid-color: #222429;

	// ORDERBOOK
	--orderbook-red: #422022;
	--orderbook-green: #123325;
	--orderbook-red-primary: #ff5f66;
	--orderbook-red-secondary: rgba(255, 52, 76, 0.6);
	--orderbook-green-primary: #21c177;
	--orderbook-green-secondary: rgba(33, 193, 119, 0.6);
	--orderbook-hover-background: #2f343d59;
	--orderbook-active-red: #ff453a;
	--orderbook-active-green: #32d74b;
	--orderbook-option-green: #32d74b;
	--orderbook-option-red: #ff453a;
	--orderbook-option-red-alpha: #ff443a80;
	--orderbook-option-green-alpha: #32d74b7a;
	--orderbook-param-name: #abb4bd;

	// BUTTON
	--button-filled-disabled-color: #777777;
	--button-filled-disabled-background: #303236;

	--button-filled-darkness-background: #2c2c2e;
	--button-filled-darkness-background-hover: #27272c;
	--button-filled-darkness-background-active: #2c2c2e;
	--button-text-darkness-background: #ffffff;

	// CARD
	--card-background-color: #202028;
	--card-background-color-secondary: #1e1e1e;
	--card-header-background: #22242a;
	--card-header-secondary: #222429;
	--card-border-color: #303236;
	--card-shadow: unset;
	--card-outline-secondary: #222429;
	--card-outlined-disabled: #303236;
	--card-outlined-border-color: #303236;

	// INPUT
	--input-normal: #40454f;
	--input-hover: #525660;
	--input-focused: #007aff;
	--input-error: #ff5f66;
	--input-disabled: #3e4249;
	--input-background: #2c2c2e;
	--input-slider-thumb-background: #787878;

	// INPUT-LABEL
	--input-label-normal: #a9acb0;
	--input-label-disabled: #656565;
	--input-label-active: #007aff;

	// TOOLTIP
	--tooltip-icon-color: #d8d8d8;
	--tooltip-icon-color-hover: #4a4a4a;
	--tooltip-background: #4a4a4a;
	--tooltip-color: #d8d8d8;

	// DROPDOWN MENU
	--dropdown-menu-item-background-hover: #17191b;
	--dropdown-menu-item-background-color: #2c2c2e;

	// SELECT
	--select-shadow: "0px 0px 9px rgba(0, 0, 0, 0.7)";

	// PROGRESS
	--progress-background-color: #252525;
	--progress-fill-color: #ff542e;
	--progress-canceled-fill-color: #787878;

	// CUSTOM SEARCH INPUT
	--custom-search-input-background: #1e1e1e;

	// FLAT BUTTON
	--button-flat-disabled: #656565;

	--terminal-scroll-track: #222429;
	--terminal-scroll-thumb: #444444;

	// PROMO
	--promo-link-button: #131f2c;

	// COIN
	--coin-detail-text: #e6e6e6;
	--coin-link: #3a3a3c;

	// Slider
	--slider-background: #1c1c1e;

	// HomePage
	--main-text-color: #fff;
	--secondary-text-color: #9a9aa3;
	--input-border-color: #484a56;
	--input-border-color--hover: #9d82ff;
	--input-border-color--focus: #7c59ff;
	--input-background-color: transparent;
	--input-text-color: #fff;
	--input-text-color--placeholder: #7f829a;
	--headline-home-page: var(--main-text-color);
	--content-text-home-page: var(--main-text-color);
	--arrow-button-background-color-home-page: #202028;

	// banner
	--banner-headline-background: var(--main-text-color);
	--banner-content-color: var(--main-text-color);
	--banner-slider-background-color: rgba(32, 32, 40, 0.6);
	--banner-slider-box-shadow-color: drop-shadow(0 14px 27px rgba(0, 0, 0, 0.1));
	--banner-slider-coin-name-color: var(--main-text-color);
	--banner-slider-headline-color: var(--main-text-color);
	--banner-slider-value-color: var(--main-text-color);
	--banner-slider-wave-opacity: 0;

	//benefits
	--benefits-nav-text-color: var(--main-text-color);
	--benefits-nav-list-border-color: #484a56;
	--benefits-nav-text-color--active: #7c59ff;
	--benefits-nav-border-color--active: #7c59ff;
	--benefits-nav-border-color: #7c59ff;
	--benefits-nav-svg-color--active: #7c59ff;
	--benefits-background-color: #202028;
	--benefits-border-color: transparent;
	--benefits-headline-color: var(--main-text-color);
	--benefits-content-text-color: var(--main-text-color);
	--benefits-content-secondary-headline-color: var(--main-text-color);

	//Supports
	--support-background-color: #202028;
	--support-text-color: #fff;
	--support-headline-color: #fff;

	//USP
	--usp-background-color: #202028;
	--usp-text-color: #fff;
	--usp-headline-color: #fff;
	--usp-headline-background-color: rgba(124, 89, 255, 0.45);

	//Partners
	--partners-before-background: linear-gradient(-90deg, rgba(16, 16, 20, 0) 0%, #101014 100%);
	--partners-after-background: linear-gradient(90deg, rgba(16, 16, 20, 0) 0%, #101014 100%);
	--partners-item-border-color: #484a56;

	//MobileApp
	--mobile-app-background: #202028;
	--mobile-app-item-text-color: #fff;

	//Communities
	--communities-background: #202028;
	--communities-link-name-text-color: #fff;
	--communities-link-count-text-color: #9a9aa3;
	--communities-link-count-text-color--hover: #7c59ff;

	//Footer
	--footer-background: #202028;
	--footer-svg-fill: #202028;
	--footer-column-headline-color: #fff;
	--footer-column-copy-color: #9a9aa3;
	--footer-column-link-color: #9a9aa3;
	--footer-column-link-color--mobile: #9a9aa3;
	--footer-column-link-color--hover: #7c59ff;
}

html {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	overflow: auto;
	font-family: "Roboto", sans-serif;
	color: var(--color-primary);
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	line-height: 1.5;
}

body,
html {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

// TODO
body {
	background-color: var(--redesigned-color-background-primary);

	&::-webkit-scrollbar {
		width: 8px;
		margin-left: 3px;
		height: 3px;
	}

	&::-webkit-scrollbar-track {
		background: var(--terminal-scroll-track);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--terminal-scroll-thumb);
		border-radius: 12px;

		&:hover {
			background-color: var(--terminal-scroll-thumb);
		}
	}

	scrollbar-width: thin;

	@include max-w(xs) {
		&::-webkit-scrollbar {
			width: 3px;
			margin-left: 2px;
			height: 3px;
		}
	}
}

h1,
h2 {
	line-height: 1.2;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

button {
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

button:active,
button:focus {
	outline: none;
	text-decoration: none;
}

a {
	text-decoration: none;
	color: var(--color-blue);
}

textarea,
button {
	font-family: "SourceSans3", "Roboto", sans-serif;
}

input {
	font-family: "SourceSans3", "Roboto", sans-serif;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
}

.main {
	width: 100%;
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	min-width: 320px;
	color: var(--redesigned-color-text-primary);
}

div,
ul {
	@include tiny-scrollbar();
}

i {
	color: var(--icon-primary);
}

.layout {
	padding: 0;
	flex: 1 0 auto;
	width: 100%;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	background-color: #101014;

	&--auth {
		display: flex;
		flex: 1;
		background-color: #fff;
	}

	&.primary {
		background-color: var(--card-background-color);
	}

	@include max-w(xl) {
		left: 0;
	}
}

.__react_component_tooltip.show {
	opacity: 1 !important;
	transition: opacity 0.12s ease-out !important;
	color: var(--tooltip-color);
	font-size: 12px;
	line-height: 14px;
	padding: 4px 8px;
}

body .__react_component_tooltip {
	padding: 2px 8px;
	font-size: 12px;
}

.chart-container {
	flex: 1;
	position: relative;
}

.text-underline {
	text-decoration: underline;
}

.text-center {
	text-align: center;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;

	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
	outline: 0;
	outline-offset: 0;
}
