@import "styles/redesigned/functions";
@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.repayModal {
	display: flex;
	flex-direction: column;
	align-items: stretch;
  gap: rem(14);

  hr {
    padding: 0;
    margin: 0;
    width: 100%;
    height: rem(1);
    border: 0;
    background-color: var(--redesigned-color-separator-opaque);
  }

	.all span {
		font-size: inherit;
	}

  .footer {
    display: flex;
    flex-direction: column;

    p {
      padding: 0;
      margin: 0;
      font-size: rem(12);
    }
  }
}
