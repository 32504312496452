@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.wallets {
	.heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rem(8);
	}

	.info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-radius: rem(16);
		padding: rem(20);
		margin-bottom: rem(72);
		background-color: var(--redesigned-color-background-base-3);

		div:first-child {
			display: flex;
			flex-direction: column;
			gap: rem(8);

			.title {
				font-size: rem(12);
				font-weight: 500;
				line-height: rem(16);
				color: var(--redesigned-color-label-secondary);
			}

			.amount {
				font-size: rem(32);
				font-weight: 500;
				line-height: rem(40);
				color: var(--redesigned-color-text-primary);
			}
		}

		div:last-child {
			display: flex;
			flex-direction: row;
			gap: rem(12);

			button {
			}
		}
	}

	.currency > div {
		gap: rem(8) !important;

		&:not(:has(img)) {
			padding-left: rem(40);
		}
	}

	.actions > div {
		gap: rem(12) !important;

		button {
			i {
				font-size: rem(16) !important;
			}

			span {
				font-size: rem(12) !important;
			}
		}
	}
}

.transfer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rem(16);

	.direction {
		--transfer-change-button-width: #{rem(52)};
		--transfer-direction-gap: #{rem(8)};
		--transfer-type-width: calc(
			(100% - var(--transfer-change-button-width) - var(--transfer-direction-gap) * 2) / 2
		);

		display: grid;
		grid-template-columns: var(--transfer-type-width) auto var(--transfer-type-width);
		align-items: flex-end;
		gap: var(--transfer-direction-gap);

		.switch {
			width: var(--transfer-change-button-width);
			height: var(--transfer-change-button-width);
			border-radius: rem(12);
			background-color: var(--redesigned-color-background-base-1);
		}
	}

	footer {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rem(16);

		button {
			flex-grow: 1;
		}
	}
}
