@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.qrPanel {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: rem(20);
	padding: rem(16);
	border-radius: rem(12);
	border: 0;
	background-color: var(--redesigned-color-background-base-1);
	overflow: hidden;

	.qr {
		width: rem(122) !important;
		height: rem(122) !important;
		padding: rem(8);
		border-radius: rem(12);
		overflow: visible;
		background-color: white;
	}

	.data {
		align-self: stretch;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: rem(16);

		.title {
			text-align: left;
			font-size: rem(16);
			font-weight: 500;
			line-height: rem(22);
			color: var(--redesigned-color-text-primary);
		}

		.code {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rem(16);
			padding: rem(12) rem(16);
			border-radius: rem(12);
			background-color: var(--redesigned-color-background-base-2);

			& > span {
				flex-grow: 1;
				text-align: left;
				font-size: rem(16);
				font-weight: 500;
				line-height: rem(22);
				word-wrap: anywhere;
				color: var(--redesigned-color-text-primary);
			}

			hr {
				width: rem(1);
				height: rem(24);
				border-radius: rem(1);
				border: 0;
				background-color: var(--redesigned-color-separator-opaque);
			}

			button {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(4);
				opacity: 0.5;
				transition: opacity 0.2s;

				@include hover {
					opacity: 1;
				}

				span {
					font-size: rem(14);
					font-weight: 500;
					line-height: rem(20);
				}

				* {
					color: var(--redesigned-color-text-primary);
				}
			}
		}
	}
}
