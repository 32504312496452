$mobile: 640;
$tablet-portrait: 1024;
$tablet-landscape: 1280;
$desktop: 1600;

@mixin mobile {
  @media screen and (max-width: #{$mobile - 1}px) {
    @content;
  }
}

@mixin not-mobile {
  @media screen and (min-width: #{$mobile}px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: #{$tablet-portrait}px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: #{$tablet-landscape - 1}px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$tablet-landscape}px) {
    @content;
  }
}

@mixin desktop-large {
  @media screen and (min-width: #{$desktop}px) {
    @content;
  }
}

@mixin cursorscreen {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin touchscreen {
  @media (hover: none), (pointer: coarse) {
    @content;
  }
}

@mixin hover {
  @include cursorscreen {
    &:hover {
      @content;
    }
  }
}
