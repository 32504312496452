@import "src/assets/styles/common/mixins";

.marginModeModal {
	--offset: 8px;
	--radius: 16px;
	--padding: 20px;
	--header-font: 24px;
	--close-button: 32px;
	--close-button-radius: 8px;
	--close-button-icon: 16px;
	--content-direction: row;
	--content-gap: 16px;
	--item-radius: 10px;
	--item-padding: 20px;
	--item-title-font: 16px;
	--item-font: 14px;
	--item-gap: 16px;
	--item-width: 350px;
	--section-check: 20px;
	--item-footer-display: flex;
	--item-title-action: none;

	@include max-w(md) {
		--content-direction: column;
		--item-width: 100%;
		--item-footer-display: none;
		--item-title-action: block;
	}

	position: absolute;
	top: var(--offset);
	right: var(--offset);
	bottom: var(--offset);
	max-width: calc(100% - var(--offset) * 2);
	padding: var(--padding);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: var(--padding);
	overflow-y: auto;
	scrollbar-width: auto;
	scrollbar-gutter: stable;
	border-radius: var(--radius);
	color: var(--color-text-primary);
	border: 1px solid var(--color-separator-opaque);
	background-color: var(--main-background-color);
	transform: translateX(50%) !important;

	.header {
		top: calc(var(--padding) * -1);
		position: sticky;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--padding);
		padding: var(--padding);
		margin: calc(var(--padding) * -1);
		backdrop-filter: blur(20px);
		z-index: 10;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			z-index: -1;
			background-color: var(--main-background-color);
		}

		span {
			flex-grow: 1;
			font-size: var(--header-font);
			font-weight: 500;
		}

		button {
			width: var(--close-button);
			height: var(--close-button);
			border-radius: var(--close-button-radius);
			border: 1px solid var(--color-separator-opaque);

			svg {
				width: var(--close-button-icon);
				height: var(--close-button-icon-button);

				path {
					stroke: var(--color-text-primary);
				}
			}
		}
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: var(--content-direction);
		align-items: stretch;
		gap: var(--content-gap);

		.item {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: var(--item-gap);
			max-width: var(--item-width);
			padding: var(--item-padding);
			font-size: var(--item-title);
			border-radius: var(--item-radius);
			border: 1px solid transparent;
			background-color: var(--color-background-primary);
			transition: border-color 0.3s;

			&.active {
				border-color: var(--input-border-color--focus);
			}

			.title {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				& > :first-child {
					display: flex;
					flex-direction: column;
					gap: 4px;

					:first-child {
						font-size: var(--item-title-font);
					}

					:last-child {
						color: var(--color-text-tertiary);
					}
				}

				.action {
					display: var(--item-title-action);

					button,
					span {
						width: 120px;
						text-align: center;
					}

					button {
						min-height: 0;
						height: 40px;
					}

					& > span {
						display: block;
						text-align: center;
						line-height: 40px;
						font-weight: 500;
						color: var(--input-border-color--focus);
					}
				}
			}

			hr {
				width: 100%;
				height: 1px;
				border: 0;
				margin: calc(-0.25 * var(--item-gap)) 0;
				background-color: var(--color-separator-opaque);
			}

			.section {
				& > span {
					display: block;
					margin-bottom: 8px;
					color: var(--color-text-tertiary);
				}

				ul {
					display: flex;
					flex-direction: column;
					align-items: stretch;
					gap: 4px;
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 9px;
						line-height: calc(var(--item-font) * 1.43);

						svg {
							width: var(--section-check);
							min-width: var(--section-check);
							aspect-ratio: 1;
						}

						div {
							align-self: flex-start;
						}
					}
				}
			}

			.footer {
				flex-grow: 1;
				display: var(--item-footer-display);
				flex-direction: column;
				justify-content: flex-end;

				button,
				span {
					width: 100%;
					text-align: center;
				}

				button {
					min-height: 0;
					height: 40px;
				}

				& > span {
					display: block;
					text-align: center;
					line-height: 40px;
					font-weight: 500;
					color: var(--input-border-color--focus);
				}
			}
		}
	}
}
