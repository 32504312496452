@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.collapser {
  --collapser-height: #{rem(52)};

  position: relative;
  height: calc(var(--collapser-height) + var(--content-height));
  overflow: hidden;
  border-radius: rem(16);
  border: rem(1) solid transparent;
  background-color: var(--redesigned-color-background-base-3);
  transition: height 0.3s;

  @include hover {
    border-color: var(--redesigned-color-separator-opaque);
  }

  &.opened {
    border-color: var(--redesigned-color-separator-opaque);
  }

  &:has(> button:disabled) button {
    pointer-events: none;
    opacity: 0.3;
  }

  &:has(> button:active) {
    box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
  }

  & > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(20);
    width: 100%;
    height: var(--collapser-height);
    padding: 0 rem(20);
    box-sizing: border-box;
    border-radius: rem(16);

    i {
      font-size: rem(14);
      color: var(--redesigned-color-text-primary);
    }

    span {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: rem(14);
      font-weight: 500;
      color: var(--redesigned-color-text-primary);
    }
  }

  .content {
    padding: rem(16) rem(20);
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(20);
  }
}
