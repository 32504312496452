@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.miniButton {
	--icon-size: rem(16);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: rem(4);
	outline: 0;
	height: rem(32);
	min-width: rem(32);
	padding: 0 rem(12);
	border-radius: rem(8);
	border: rem(1) solid transparent;
	background: transparent;
	transition: border-color 0.2s;

	&.bordered {
		border-color: var(--redesigned-color-separator-opaque);
	}

	&:active {
		border-color: var(--redesigned-color-separator-opaque);
		box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
	}

	& > span {
		white-space: nowrap;
		color: var(--redesigned-color-text-primary);
		font-size: rem(14);
		font-weight: 500;
	}

	& > i {
		color: var(--redesigned-color-text-primary);
		font-size: var(--icon-size);
		transition: color 0.2s;
	}

	& > img {
		width: var(--icon-size);
		height: var(--icon-size);
		object-fit: contain;
	}

	&:not(:has(span)) {
		padding: 0;

		& > i {
			color: var(--redesigned-color-label-secondary);
		}
	}

	&:not(:disabled) {
		@include hover {
			border-color: var(--redesigned-color-separator-non-opaque);

			i {
				color: var(--redesigned-color-text-primary) !important;
			}
		}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.36;
	}
}
