@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.input {
	--input-height: #{rem(52)};
	--input-padding: #{rem(16)};
	--input-radius: #{rem(12)};
	--input-font: #{rem(16)};
	--input-icon: #{rem(20)};
	--input-reset-padding: #{rem(4)};
	--input-reset-icon: #{rem(20)};

	&.mini {
		--input-height: #{rem(30)};
		--input-padding: #{rem(8)};
		--input-radius: #{rem(8)};
		--input-font: #{rem(14)};
		--input-icon: #{rem(16)};
		--input-reset-padding: #{rem(2)};
		--input-reset-icon: #{rem(16)};
	}

	--input-color-border: var(--redesigned-color-separator-opaque);
	--input-color-text: var(--redesigned-color-text-primary);

	&.error {
		--input-color-border: var(--redesigned-color-accent-red);
		--input-color-text: var(--redesigned-color-accent-red);
	}

	display: inline-flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: var(--input-height);
	border-radius: var(--input-radius);
	overflow: hidden;
	border: rem(1) solid transparent;
	background-color: var(--redesigned-color-background-base-1);
	cursor: text;
	transition: border-color 0.2s, opacity 0.2s;

	@include hover {
		border-color: var(--input-color-border);
	}

	&:has(input:active),
	&:has(input:focus),
	&.error {
		border-color: var(--input-color-border);
	}

	&:has(input:active) {
		box-shadow: inset 0 0 0 rem(1) var(--input-color-border);
	}

	&:has(input:disabled) {
		pointer-events: none;
		opacity: 0.32;
	}

	.icon {
		max-width: var(--input-height);
		max-height: var(--input-height);
		overflow: hidden;
		margin-left: var(--input-padding);

		& > i {
			font-size: var(--input-icon);
			color: var(--redesigned-color-label-secondary);
		}
	}

	input {
		align-self: stretch;
		flex-grow: 1;
		padding: 0 var(--input-padding);
		font-size: var(--input-font);
		outline: none;
		border: 0;
		color: var(--input-color-text);
		background: none;

		&::placeholder {
			font-size: var(--input-font);
			color: var(--redesigned-color-label-quarternary);
			opacity: 0.5;
		}
	}

	.reset {
		display: flex;
		margin-right: var(--input-padding);
		padding: var(--input-reset-padding);
		border-radius: rem(6);
		border: rem(1) solid transparent;
		transition: border-color 0.2s;

		@include hover {
			border-color: var(--redesigned-color-separator-opaque);
		}

		svg {
			width: var(--input-reset-icon);
			height: var(--input-reset-icon);

			path {
				stroke: var(--redesigned-color-label-secondary);
			}
		}
	}

	.action {
		margin-right: var(--input-padding);
		font-size: var(--input-font);
		font-weight: 500;
		outline: none;
		border: 0;
		color: var(--redesigned-color-accent-purple);
		background: none;

		&:disabled {
			cursor: not-allowed;
			color: var(--redesigned-color-label-quarternary);
		}
	}
}
