.orderConfirmation {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 5px;

	& > div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;

		span:first-child {
			opacity: 0.6;
		}

		span:last-child {
			font-weight: 500;
		}
	}
}
