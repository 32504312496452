@import "styles/mixins";

.header {
	background-color: var(--main-background-color);
	position: relative;
	border-bottom: 1px solid var(--header-separator-color);
	z-index: 10000;

	box-sizing: border-box;

	&__wrapper {
		display: flex;
		align-items: center;
		padding: 0 20px;

		@media screen and (max-width: 1024px) {
			padding: 9px 13px;
		}

		&___landing {
			max-width: 1660px;
			margin: 0 auto;
		}
	}

	* {
		box-sizing: border-box;
	}
}
