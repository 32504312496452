:root {
  --redesigned-color-text-primary: #ffffff;
  --redesigned-color-label-secondary: #EBEBF5A3;
  --redesigned-color-label-tertiary: #EBEBF54D;
  --redesigned-color-label-quarternary: #EBEBF52E;

  --redesigned-color-background-primary: #000000;
  --redesigned-color-background-base-1: #19191D;
  --redesigned-color-background-base-2: #222225;
  --redesigned-color-background-base-3: #101014;
  --redesigned-color-background-tertiary: #3A3A3C;

  --redesigned-color-separator-opaque: #403F455C;
  --redesigned-color-separator-non-opaque: #212125;

  --redesigned-color-accent-purple: #7C59FF;
  --redesigned-color-accent-red: #EF454A;
  --redesigned-color-accent-brown: #6c451c;
  --redesigned-color-accent-brown-light: #83521b;
}
