.list {
	display: flex;
	padding-bottom: 36px;

	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -10px;
	}

	&__empty {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__listWrapper {
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;
		white-space: nowrap;

		.list__itemsDropdown {
			display: none;
		}

		&:first-child {
			.list__headlineText:first-child {
				margin-left: 16px;
			}

			.list__itemsDropdown {
				display: flex;
				position: absolute;
			}

			.list__itemElem:first-child {
				margin-left: 16px;
			}

			.list__item {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 250px;
					border-left: 2px solid #20b26c;
					background: linear-gradient(90deg, rgba(50, 215, 75, 0.08) 0%, rgba(50, 215, 75, 0) 100%);
				}

				&___sell {
					&::before {
						border-left: 2px solid #ef454a;
						background: linear-gradient(
							90deg,
							rgba(255, 69, 58, 0.08) 0%,
							rgba(255, 69, 58, 0) 100%
						);
					}
				}

				&___cancelled {
					&::before {
						border-left: 2px solid rgb(255, 255, 255);
						background: linear-gradient(
							90deg,
							rgba(255, 255, 255, 0.2) 0%,
							rgba(255, 69, 58, 0) 100%
						);
					}
				}
			}
		}

		&___scroll {
			position: relative;
			overflow: hidden;
			flex: 1;

			.list__item {
				flex: 1;
				//justify-content: space-between;
			}
		}

		&___arrows {
			& + .list__listWrapper {
				background-color: #1c1c1e;
			}
		}
	}

	&__itemState {
		text-transform: capitalize;
	}

	&__listContainer {
		display: flex;
		flex-direction: column;

		&___scroll {
			overflow: auto;
			scrollbar-width: none;
			flex: 1;

			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			.list__item___open {
				&::after {
					display: flex;
					z-index: 0;
					content: "";
					background-color: #3a3a3c;
					width: 100%;
					left: 0;
					position: absolute;
					height: 36px;
					z-index: 0;
					pointer-events: none;
				}
			}

			.list__item:hover {
				.list__itemButtonLeft {
					&___show {
						opacity: 1;
						pointer-events: auto;
					}
				}

				.list__itemButtonRight {
					&___show {
						opacity: 1;
						pointer-events: auto;
					}
				}
			}
		}
	}

	&__items {
		list-style: none;
		margin: 0;
		padding: 0;
		flex: 1;
		justify-content: space-between;
	}

	&__item {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0 0 0 0;
		height: 36px;

		.list__itemElem___directionBuy span {
			color: #20b26c;
		}

		&___sell {
			.list__itemElem___direction span {
				color: #ef454a;
			}
		}

		&___pending {
			.list__itemElem___direction span {
				color: rgba(235, 235, 245, 0.6);
			}
		}

		&:not(.list__item___open) {
			margin-bottom: 2px !important;
		}

		&___open {
			background-color: #3a3a3c;

			& + .list__itemsDropdown {
				opacity: 1;
				pointer-events: auto;
			}

			.list__itemButtonRight___show {
				background: linear-gradient(90deg, transparent 0, #3a3a3c 60%, #3a3a3c 100%);
				opacity: 1;
			}

			.list__itemButtonLeft___show {
				background: linear-gradient(-90deg, transparent 0, #3a3a3c 60%, #3a3a3c 100%);
				opacity: 1;
			}
		}
	}

	&__itemsDropdown {
		display: flex;
		flex-direction: column;
		background-color: #2c2c2e;
		z-index: 100;
		width: 100%;
		opacity: 0;
		margin-top: -2px;
		pointer-events: none;
	}

	&__itemsDropdownHeader {
		padding: 8px 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media (max-width: 1440px) {
			padding: 8px 16px;
		}
	}

	&__itemsDropdownItem {
		padding: 8px 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media (max-width: 1440px) {
			padding: 8px 16px;
		}
	}

	&__itemsDropdownHeaderText {
		display: flex;
		padding: 0;
		flex: 1;
		margin: 0;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}

	&__itemsDropdownItemText {
		display: flex;
		padding: 0;
		flex: 1;
		margin: 0;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	&__itemsDropdownItemCurrencyText {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		margin-left: 3px;
	}

	&__itemElem {
		position: relative;
		z-index: 1;
		margin: 0 0 0 8px;
		width: auto;

		span {
			display: flex;
			color: #fff;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}

		.list__itemCurrency {
			display: inline-flex;
			color: rgba(235, 235, 245, 0.6);
			font-size: 12px;
			font-weight: 400;
			line-height: 19px;
			margin-left: 3px;
		}

		.list__itemEditButton {
			margin-right: 4px;
			background: #7c59ff;
			width: 22px;
			height: 22px;
			padding-top: 2px;
			padding-left: 2px;
			border-radius: 4px;

			svg {
				width: 14px;
				height: 14px;
				fill: #fff;
			}
		}

		.list__itemCrossButton {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 4px;
			background: #7c59ff;
			width: 22px;
			height: 22px;
			border-radius: 4px;

			svg {
				width: 15px;
				height: 15px;
				fill: #fff;

				path {
					fill-opacity: 1;
				}
			}
		}

		.list__itemEditInput {
			display: flex;
			align-items: center;
			border: 1px solid rgba(255, 255, 255, 0.4);
			background: transparent;
			font-family: inherit;
			font-size: inherit;
			padding: 1px 6px;
			border-radius: 4px;
			max-width: 83px;
			min-width: 40px;
			overflow: hidden;
			color: #fff;
			height: 22px;

			&___qty {
				max-width: 112px;
			}

			&:focus {
				border: 1px solid #7c59ff;
				outline: none;
			}
		}

		.list__itemCheckButton {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 4px;
			background: #7c59ff;
			width: 22px;
			height: 22px;
			border-radius: 4px;

			svg {
				width: 15px;
				height: 15px;

				path {
					stroke: #fff;
				}
			}
		}

		&___direction span {
			color: #20b26c;
		}
	}

	&__itemWithCurrency {
		display: inline-flex;
		align-items: flex-end;
	}

	&__headline {
		display: flex;
		list-style: none;
		align-items: center;
		padding: 0;
		margin: 0;
		flex: 1;
		//justify-content: space-between;
		max-height: 30px;

		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}

	&__headlineTextUnderline {
		text-decoration: underline;
	}

	&__headlineText {
		margin-left: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__itemButton {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: #2c2c2e;
		padding: 0 10px;
		height: 24px;
		position: relative;

		span {
			color: #fff;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			transition: 0.3s ease-in-out;
		}

		&___details {
			padding: 0 8px 0 10px;

			svg {
				margin-left: 2px;
				transition: 0.3s ease-in-out;
			}

			&___show {
				svg {
					transform: rotate(-180deg);
				}
			}
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}

		&:disabled {
			.list__itemButtonLoader {
				opacity: 1;
			}

			span {
				opacity: 0;
			}

			@media (hover: hover) and (pointer: fine) {
				transition: 0.3s ease-in-out;

				&:hover {
					cursor: progress;
					opacity: 1;
				}
			}
		}
	}

	&__itemButtonLoader {
		position: absolute;
		transition: 0.3s ease-in-out;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 18px;
		opacity: 0;
	}

	&__listButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		top: 0;
		height: 100%;
		background: linear-gradient(
			90deg,
			var(--main-background-color),
			var(--main-background-color),
			transparent
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 10px;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
			z-index: 3;
		}
	}

	&__listButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		top: 0;
		height: 100%;
		background: linear-gradient(90deg, transparent 0, #1c1c1e 60%, #1c1c1e 100%);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 15px;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
			z-index: 3;
		}
	}

	&__itemButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		height: 36px;
		background: transparent;
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 10px;
		z-index: 4;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 0;
			pointer-events: auto;
		}
	}

	&__itemButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		height: 36px;
		background: transparent;
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 15px;
		z-index: 4;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 0;
			pointer-events: auto;
		}
	}
}
