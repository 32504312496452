@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.closedOrders {
	.table {
		margin-top: rem(20);
		margin-bottom: rem(70);

		tr:has(.cancelled) {
			opacity: 0.5;
		}
	}
}
