@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.codeInput {
	--input-height: #{rem(52)};
	--input-radius: #{rem(12)};
	--input-font: #{rem(20)};

	--input-color-border: var(--redesigned-color-separator-opaque);
	--input-color-text: var(--redesigned-color-text-primary);

	&.error {
		--input-color-border: var(--redesigned-color-accent-red);
		--input-color-text: var(--redesigned-color-accent-red);
	}

	display: inline-flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	& > div {
		width: 100%;
		justify-content: space-between;
		gap: rem(8);

		& > div {
			width: var(--input-height);
		}
	}

	input {
		width: 100% !important;
		height: var(--input-height);
		font-size: var(--input-font);
		font-weight: 600;
		border-radius: var(--input-radius);
		border: rem(1) solid transparent;
		outline: none;
		color: var(--input-color-text);
		background-color: var(--redesigned-color-background-base-1);
		transition: border-color 0.2s, opacity 0.2s;

		&::placeholder {
			color: var(--redesigned-color-label-quarternary);
		}

		&:active {
			box-shadow: inset 0 0 0 rem(1) var(--input-color-border);
		}

		&:disabled {
			pointer-events: none;
			opacity: 0.32;
		}
	}

	@include cursorscreen {
		&:has(input:hover) input {
			border-color: var(--input-color-border);
		}
	}

	&:has(input:active),
	&:has(input:focus),
	&.error {
		input {
			border-color: var(--input-color-border);
		}
	}
}
