@import "src/assets/styles/common/mixins";

.row {
	@include flexbox(space-between, center);
	position: relative;
	width: auto;

	&.full_width {
		width: 100%;
	}
}

.currency_code {
	display: flex;
	align-items: center;
	margin-left: 10px;
	font-weight: normal;
	font-size: 14px;
	color: var(--color-primary);
	white-space: nowrap;

	i {
		font-size: 18px;
		line-height: 18px;
		margin: 0 15px 1px 0;
		width: 16px;
	}

	img {
		width: 24px;
		height: 24px;
		margin: 0 8px 1px 0;
	}

	.currency_deflogo {
		margin: 0 15px 1px 0;
	}
}

.currency_available {
	margin-left: auto;
	color: var(--color-primary);

	&.secondary {
		color: var(--color-secondary);
	}
}

.currency_name {
	color: var(--color-secondary);
	margin-left: 5px;
	font-size: 12px;
	width: auto;
	word-break: break-all;
}

.label {
	font-weight: normal;
	z-index: 2;
	color: var(--color-secondary);
	transition: 0.12s ease;
	position: absolute;
	top: -19px;
	left: 0;
	font-size: 12px;
	line-height: 14px;
	padding: 0 4px;
	width: max-content !important;
	z-index: 2;
	margin-bottom: 2px;
}

.currency_dropdown_indicator {
	position: absolute;
	right: 0;
	padding: 10px;
	width: 46px;
	height: 46px;
	@include flexbox(center, center);
	pointer-events: none;

	i {
		font-size: 12px;
		color: var(--icon-primary);
	}

	&.mini {
		width: 32px;
		height: 32px;

		i {
			font-size: 9px;
		}
	}
}

.currency_dropdown {
	display: flex;
	position: relative;
	width: 100%;
	align-items: center;
	margin: 0;
	cursor: pointer;
	padding: 0 20px 0 0;
	transition: 0.12s ease;
	height: 40px;
	box-shadow: none;
	background: #2c2c2e;
	border-radius: 8px;

	&.mini {
		height: 36px;
	}

	&.error {
		border-color: var(--input-error);
	}

	&.active {
		//box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.12);
	}

	&:hover {
		border-color: var(--color-blue);
	}
}

.currency_placeholder {
	width: 100%;
	@include flexbox(flex-start, center);
	padding: 0 12px;
	color: var(--input-label-normal);
}

.currency_value_container {
	padding: 12px 10px;
	@include flexbox(center, center);
	width: 100%;
	position: relative;

	input {
		width: 100%;
	}

	i {
		position: absolute;
		font-size: 13px;
		left: 19px;
		transform: translateY(1px);
	}
}

.selected_mark {
	height: 100%;
	@include flexbox(center, center);
	margin-left: 8px;

	i {
		font-size: 14px;
		color: var(--color-green);
	}
}

.error_text {
	padding: 2px 5px 0 2px;
	font-size: 12px;
	margin-top: 7px;
	color: var(--input-error);
	line-height: 14px;
	text-align: left;

	@include max-w(xs) {
		font-size: 11px;
		padding: 2px 5px;
	}
}

.container {
	width: 100%;
	position: relative;
	z-index: 10;

	&__label {
		margin-top: 12px;
	}

	&.disabled {
		* {
			pointer-events: none;
			filter: grayscale(100%);
			user-select: none;
			color: var(--input-label-disabled);
		}

		& > .currency_dropdown {
			border-color: var(--input-disabled);
		}

		& > .currency_placeholder {
			color: var(--input-label-disabled);
		}

		& > .currency_dropdown_indicator {
			i {
				color: var(--input-label-disabled);
			}
		}
	}

	&.focused {
		& > .currency_dropdown {
			border-color: var(--input-label-active);
			border-bottom: 1px solid var(--input-normal);
			border-radius: 8px 8px 0 0;
		}
	}
}
