@import "./functions";

@mixin scroll {
	scrollbar-width: auto;
	scrollbar-color: var(--color-separator-opaque) var(--main-background-color);

	&::-webkit-scrollbar {
		width: rem(14);
		height: rem(14);
	}

	&::-webkit-scrollbar-track {
		background: var(--main-background-color);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: rem(10);
		border: rem(3) solid transparent;
		background-clip: content-box;
		background-color: var(--color-separator-opaque);
	}
}

@mixin font($size, $weight: false, $line: false) {
	font-size: rem($size);
	@if $weight {
		font-weight: $weight;
	}
	@if $line {
		line-height: rem($line);
	}
}
