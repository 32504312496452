.button {
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1em;
		width: 100%;
		color: #fff;
		font-size: 1em;
		font-weight: 600;
		line-height: 125%;
		border-radius: 1em;
		background: #7c59ff;
		transition: 0.3s ease-in-out;
		gap: 0.25rem;

		&___transparent {
			color: #7c59ff;
			background: transparent;
		}

		&___loading {
			pointer-events: none;
		}

		@media (hover: hover) {
			&:hover {
				opacity: 0.88;
			}
		}

		&:active {
			opacity: 0.76;
		}

		&:disabled {
			opacity: 0.24;
			pointer-events: none;
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1.25em;

		svg {
			height: 2em;
			width: 2em;
		}
	}
}
