@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.withdrawal {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rem(72);

	.main {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: rem(12);

		.info {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: rem(12);
			width: 50%;
			max-width: 440px;

			@include tablet-portrait {
				width: 100%;
				max-width: none;
			}

			& > header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				& > span {
					font-size: rem(16);
					font-weight: 500;
					line-height: rem(20);
				}
			}

			& > .faq {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: rem(8);

				&:not(:last-child) {
					padding-bottom: rem(12);
					border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);
				}
			}
		}

		@include tablet-portrait {
			flex-direction: column;
			gap: rem(50);
		}
	}

	.history {
		h2 {
			padding: 0;
			margin: 0;
			margin-bottom: rem(20);
			font-size: rem(20);
			font-weight: 500;
			line-height: rem(28);
		}
	}
}

.form {
	width: 100%;
	flex-grow: 1;

	.step {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rem(16);
	}

	.optional {
		color: var(--redesigned-color-label-tertiary);
	}

	.amounts {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rem(8);

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			&:not(:first-child) {
				color: var(--redesigned-color-label-secondary);
			}

			& > span {
				font-size: rem(16);
				font-weight: 500;
				line-height: rem(20);
			}
		}
	}

	.confirmation {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: rem(32);
		gap: rem(16);

		p {
			padding: 0;
			margin: 0;
			max-width: rem(220);
		}
	}
}

.success {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	img {
		width: rem(170);
		height: rem(170);
		object-fit: contain;
		margin-bottom: rem(20);
	}

	strong {
		font-size: rem(24);
		font-weight: 500;
		line-height: rem(32);
	}

	span {
		font-size: rem(14);
		font-weight: 400;
		line-height: rem(20);
		color: var(--redesigned-color-label-secondary);
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	gap: rem(20);

	.content {
		display: flex;
		flex-direction: column;
		gap: rem(20);

		.code > div {
			gap: rem(8);

			& > div {
				flex-grow: 1;
			}
		}

		.fieldIcon {
			width: rem(20);
			height: rem(20);
		}

		.resend button {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rem(8);
			font-size: rem(14);
			color: var(--redesigned-color-accent-purple);
			transition: opacity 0.2s;

			&:disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		gap: rem(16);

		button {
			flex-grow: 1;
		}
	}
}
