@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.deposit {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rem(72);

	.main {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: rem(12);

		.info {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: rem(12);
			width: 50%;
			max-width: 440px;

			@include tablet-portrait {
				width: 100%;
				max-width: none;
			}

			& > header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				& > span {
					font-size: rem(16);
					font-weight: 500;
					line-height: rem(20);
				}
			}

			& > .faq {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: rem(8);

				&:not(:last-child) {
					padding-bottom: rem(12);
					border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);
				}

				p:first-child {
					margin-top: 0;
				}

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include tablet-portrait {
			flex-direction: column;
			gap: rem(50);
		}
	}

	.history {
		h2 {
			padding: 0;
			margin: 0;
			margin-bottom: rem(20);
			font-size: rem(20);
			font-weight: 500;
			line-height: rem(28);
		}
	}
}

.form {
	width: 100%;
	flex-grow: 1;

	.step {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rem(16);
	}

	.currencies span:not(:first-child):last-child {
		display: none;
	}

	.defaults {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rem(4);
		margin-top: rem(-12);

		button {
			flex-grow: 1;
		}
	}

	.error {
		font-size: rem(16);
		font-weight: 400;
		line-height: rem(22);
		color: var(--redesigned-color-accent-red);
	}

	.qr {
		margin-top: rem(8);
	}

	.values {
		margin-top: rem(36);
	}

	.text {
		margin-bottom: rem(20);

		& > span {
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(22);
			color: var(--redesigned-color-label-secondary);
		}

		& > p {
			padding: 0;
			margin: rem(8) 0;
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(22);
			color: var(--redesigned-color-text-primary);
		}
	}

	.full {
		margin-left: rem(-34);
	}
}
