@import "styles/redesigned/functions";
@import "styles/redesigned/media";

$transition: 0.3s;

.tabs {
  --gap: #{rem(12)};

  &.compact {
    --gap: #{rem(4)};
  }

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap);
  width: min-content;

  button {
    position: relative;
    height: rem(32);
    padding: 0 rem(4);
    font-size: rem(14);
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    color: var(--redesigned-color-label-secondary);
    transition: border-color $transition, color $transition;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      visibility: hidden;
      background-image: linear-gradient(to top, var(--redesigned-color-accent-purple), transparent);
      transition: opacity $transition, visibility $transition;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    @include hover {
      color: var(--redesigned-color-text-primary);
    }

    &.selected {
      color: var(--redesigned-color-text-primary);
      border-bottom-color: var(--redesigned-color-accent-purple);
      pointer-events: none;

      &:before {
        opacity: 0.3;
        visibility: visible;
      }
    }

    span {
      white-space: nowrap;
      position: relative;
      z-index: 1;
    }
  }

  hr {
    border: 0;
    align-self: stretch;
    width: rem(2);
    margin: rem(6) 0;
    padding: 0;
    border-radius: rem(1);
    background-color: var(--redesigned-color-separator-opaque);
  }
}
