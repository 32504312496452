@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.calendar .months-scroll {
	flex-grow: 1;
	overflow-y: scroll;
	@include scroll;

	& > .months {
		position: relative;
		overflow: hidden;
		min-height: 100%;

		& > .year {
			position: absolute;
			width: 100%;
			display: flex;
			flex-direction: row;
			box-sizing: border-box;
			border-bottom: 1px solid;

			& > .year-name {
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				border-right: 1px solid;
			}

			& > .year-months {
				flex-grow: 1;
				display: grid;
				align-content: start;
				grid-template-columns: repeat(6, 1fr);

				& > div {
					display: flex;
					align-items: stretch;
					padding: 2px 0;
					height: 100%;
					position: relative;

					& > div {
						flex-grow: 1;
						display: flex;
						align-items: stretch;

						& > div {
							flex-grow: 1;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}

					.current {
						position: absolute;
						box-sizing: border-box;
						left: 50%;
						top: 50%;
						border: 2px solid;
						pointer-events: none;
					}

					&.inactive {
						pointer-events: none;
						opacity: 0.3;
					}
				}
			}
		}
	}
}
