@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.currency > div:first-child {
	--currency-icon-size: #{rem(21)};
	--currency-gap: #{rem(4)};

	gap: var(--currency-gap);

	img {
		width: var(--currency-icon-size) !important;
		height: var(--currency-icon-size) !important;
	}

	&:not(:has(img)) {
		padding-left: calc(var(--currency-icon-size) + var(--currency-gap));
	}
}
