@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.keyValues {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(5);

  & > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: rem(14);

    span:first-child {
      opacity: 0.6;
      white-space: nowrap;
    }

    span:last-child {
      padding-left: rem(20);
      flex-grow: 1;
      font-weight: 500;
      text-align: right;
      word-wrap: anywhere;
    }

    &.separated {
      padding-bottom: rem(20);
      margin-bottom: rem(15);
      border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);
    }
  }
}
