.Toastify__toast-body {
	margin: 0;
	padding: 0;

	> div {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@keyframes trackProgress {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100%);
	}
}

.Toastify__toast {
	width: 360px;
	padding: 12px 12px 12px 24px;
	line-height: 1.43;
	font-size: 14px;
	font-weight: 500;
	font-family: "Roboto", sans-serif;
	letter-spacing: -0.012em;
	word-break: break-word;
	animation-duration: 0.2s;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&-subtext {
		font-weight: 400;
		color: #EBEBF5A3;
	}

	i {
		font-size: 18px;
		margin-right: 10px;
	}

	.Toastify__progress-bar {
		border-radius: 999px;
		opacity: 1;

		&--bg {
			background-color: #2A2A2D;
			opacity: 1;
		}
	}

	.Toastify__progress-bar--wrp {
		width: 4px;
		height: calc(100% - 24px);
		left: 12px;
		top: 50%;
		overflow: hidden;
		border-radius: 999px;
		transform: translateY(-50%);
	}

	&--error {
		.Toastify__progress-bar {
			background-color: #EF454A;
		}
	}

	&--info, &--default {
		.Toastify__progress-bar {
			background-color: #7C59FF;
		}
	}

	&--warning {
		.Toastify__progress-bar {
			background-color: #FFD60A;
		}
	}

	&--success {
		.Toastify__progress-bar {
			background-color: #20B26C;
		}
	}

	&-icon {
		margin-inline-end: 12px;
	}

	.Toastify__progress-bar--animated {
		animation: trackProgress linear 1 forwards;
	}
}

.Toastify__toast-theme--dark {
	color: #fff;
	background-color: #19191D;

	i {
		color: #0a0a0a;
	}
}
.Toastify__toast-theme--light {
	color: #fff;
	background: #3e3e3e;
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.22);

	.Toastify__toast-icon {
		margin-inline-end: 12px;
		svg {
			fill: #fff;
		}
	}

	i {
		color: #fff;
	}
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	opacity: 1;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
	width: 12px;
	height: 12px;
	fill: var(--toast-close-icon-color);
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
	fill: var(--toast-close-icon-color-hover);
}
