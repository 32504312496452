.input {
	position: relative;
	//overflow: hidden;

	&___error {
		.input__error {
			opacity: 1;
			right: 1.5em;
			transform: scale(1);

			&:hover {
				&::after {
					width: 2em;
					height: 2em;
				}

				.input__errorTextWrapper {
					opacity: 1;
				}
			}
		}

		.input__password {
			right: 3.5em;
		}

		.input__element {
			border-color: #ef454a !important;
			color: #ef454a !important;
		}
	}

	&__labelWrapper {
		display: flex;
		justify-content: space-between;
	}

	&__wrapper {
		transition: 0.3s ease-in-out;
		max-height: 3.25em;
		opacity: 1;

		&___label {
			margin-top: 0.5em;
		}

		&___collapsed {
			pointer-events: none;
			max-height: 0;
			opacity: 0;
			margin-top: 0;
		}
	}

	&__label {
		color: #000;
		font-size: 0.875em;
		font-weight: 500;
		line-height: 142.857%;

		&___button {
			display: flex;
			align-items: center;
			color: #7c59ff;
			gap: 0.25em;

			svg {
				transition: 0.3s ease-in-out;
			}
		}

		&___buttonClose svg {
			transform: rotate(180deg);
		}
	}

	&__element {
		position: relative;
		z-index: 1;

		border-radius: 1em;
		border: 1px solid rgba(198, 198, 200, 0.08);
		padding: calc(1em - 1px) 1.5em;
		background: #f2f2f7;
		width: 100%;
		color: #000;
		font-size: 1em;
		font-weight: 400;
		line-height: 125%;
		transition: 0.3s ease-in-out;

		&:disabled {
			opacity: 0.24;
			pointer-events: none;
		}

		&::placeholder {
			color: rgba(60, 60, 67, 0.3);
		}

		&:hover,
		&:active,
		&:focus {
			border-color: initial;
			outline: none;
		}

		&:hover,
		&:active,
		&:focus {
			border-color: #7c59ff;
		}
	}

	&__error {
		position: absolute;
		bottom: 1em;
		right: 1px;
		width: 1.25em;
		height: 1.25em;
		opacity: 0;
		transition: 0.3s ease-in-out;
		z-index: 2;
		transform: scale(0.2);

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0;
			height: 0;
			border-radius: 50%;
			background: rgba(239, 69, 74, 0.16);
			transition: 0.3s ease-in-out;
		}
	}

	&__errorTextWrapper {
		position: absolute;
		bottom: calc(2.25em - 1px);
		padding: calc(1em - 1px) 1em;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 1em;
		border: 1px solid #ef454a;
		background: #fff;
		opacity: 0;
		transition: 0.3s ease-in-out;
		pointer-events: none;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-left: 0.5em solid transparent;
			border-right: 0.5em solid transparent;
			border-top: 0.5em solid #ef454a;
		}
	}

	&__errorText {
		white-space: nowrap;
		margin: 0;
		letter-spacing: -0.012em;
		font-size: 1em;
		line-height: 1em;
		font-weight: 500;
		color: #000;
	}

	&__password {
		font-size: inherit;
		position: absolute;
		bottom: 1em;
		right: 1.5em;
		width: 1.25em;
		height: 1.25em;
		transition: 0.3s ease-in-out;
		z-index: 2;

		&:hover {
			opacity: 0.6;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}
}
