@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.field {
  --field-label-color: var(--redesigned-color-text-primary);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(8);

  &.error {
    --field-label-color: var(--redesigned-color-accent-red);
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: rem(4);

    & > span:first-child {
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(20);
      color: var(--field-label-color);
      transition: color 0.2s;

      & > i:first-child {
        padding-left: rem(2);
        font-style: normal;
        color: var(--redesigned-color-accent-red);
      }
    }

    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(4);
      margin-left: auto;

      @include hover {
        span {
          text-decoration-color: var(--redesigned-color-accent-purple);
        }
      }

      span {
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(20);
        color: var(--redesigned-color-accent-purple);
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: 0.2s;
      }

      i {
        font-size: rem(12);
        color: var(--redesigned-color-accent-purple);
      }
    }
  }

  .subline {
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(18);
    color: var(--field-label-color);
    opacity: 0.64;
    transition: color 0.2s;
  }

  .errorText {
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(18);
    color: var(--redesigned-color-accent-red);
  }
}
