@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.detect-scroll-width {
	@include scroll;
}

.calendar {
	height: 100%;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	cursor: default;

	.header {
		.week {
			display: grid;
			border-bottom: 1px solid;
			grid-template-columns: repeat(7, 1fr);

			div {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
