@import "styles/redesigned/functions";
@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

$prefix: "react-select";

.select {
	--select-height: #{rem(52)};
	--select-padding: #{rem(16)};
	--select-radius: #{rem(12)};
	--select-font: #{rem(16)};
	--select-icon: #{rem(20)};
	--select-option-gep: #{rem(8)};

	&.mini {
		--select-height: #{rem(30)};
		--select-padding: #{rem(8)};
		--select-radius: #{rem(8)};
		--select-font: #{rem(14)};
		--select-icon: #{rem(16)};
	}

	display: inline-block;

	div[class~="#{$prefix}__menu"] {
		border-radius: rem(10);
		overflow: hidden;
		width: auto;
		min-width: 100%;
		background-color: var(--redesigned-color-background-base-1);
		scrollbar-width: auto;
		z-index: 100;
		@include scroll;
	}

	div[class~="#{$prefix}__menu-list"] {
		display: flex;
		flex-direction: column;
		gap: rem(2);
		padding: rem(4);
	}

	.control {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: var(--select-height);
		padding-right: var(--select-padding);
		border-radius: var(--select-radius);
		border: rem(1) solid transparent;
		background-color: var(--redesigned-color-background-base-1);
		transition: border-color 0.2s;

		@include hover {
			border-color: var(--redesigned-color-separator-opaque);
		}

		&:active {
			box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
		}

		svg {
			min-width: rem(16);
			max-width: rem(16);
			min-height: rem(16);
			max-height: rem(16);
		}
	}

	&.mini:has(div[class~="#{$prefix}__menu"]) .control,
	&.mini .control.selected {
		border-color: var(--redesigned-color-separator-opaque);
	}

	&.disabled .control {
		opacity: 0.32;
	}

	.option {
		display: block;
		border: 0;
		margin: 0;
		width: 100%;
		min-height: var(--select-height);
		cursor: pointer;
		border-radius: var(--select-radius);
		border: rem(1) solid transparent;
		background-color: transparent;

		@include hover {
			background-color: var(--redesigned-color-background-base-2);
		}

		&.selected {
			border-color: var(--redesigned-color-separator-opaque);
			background-color: var(--redesigned-color-background-base-2);
		}

		&:disabled {
			pointer-events: none;
			filter: grayscale(1);
			opacity: 0.3;
		}
	}

	.option,
	.value {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--select-option-gep);
		padding: 0 var(--select-padding);

		span.label {
			white-space: nowrap;
			font-size: var(--select-font);
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--redesigned-color-text-primary);
		}

		span.description {
			white-space: nowrap;
			font-size: var(--select-font);
			font-weight: 400;
			color: var(--redesigned-color-label-tertiary);
		}

		span.amount {
			flex-grow: 1;
			white-space: nowrap;
			text-align: right;
			font-size: var(--select-font);
			font-weight: 500;
			color: var(--redesigned-color-label-secondary);
		}

		i {
			font-size: var(--select-icon);
			color: var(--redesigned-color-text-primary);
		}

		img {
			width: var(--select-icon) !important;
			height: var(--select-icon) !important;
		}
	}

	&.iconed {
		.option,
		.value {
			&:has(span.label:first-child) span.label {
				padding-left: calc(var(--select-option-gep) + var(--select-icon));
			}
		}
	}

	.value {
		flex-grow: 1;
		overflow: hidden;
	}

	.search {
		flex-grow: 1;
		padding: 0 var(--select-padding);
		font-size: var(--select-font);
		font-weight: 500;
		color: var(--redesigned-color-text-primary);
		outline: 0;
		border: 0;
		background: none;
	}
}
