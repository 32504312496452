@import "styles/redesigned/functions";
@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.table {
	--table-padding: var(--horizontal-page-padding);
	--table-padding-reverse: calc(var(--table-padding) * -1);
	--table-sticky-transition: 0.2s;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	border-radius: rem(16);
	margin: 0 calc(var(--table-padding) * -1);
	width: calc(100% + var(--table-padding) * 2);
	max-width: calc(100% + var(--table-padding) * 2);

	&.loading {
		.header,
		.footer {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	.header {
		position: sticky;
		top: var(--header-height);
		padding: rem(12) rem(20);
		z-index: 6;
		border-bottom: 1px solid var(--redesigned-color-separator-opaque);
		background-color: var(--redesigned-color-background-base-3);
		transition: var(--table-sticky-transition);

		&:not(.full) {
			margin: 0 var(--table-padding);
			border-top-left-radius: rem(16);
			border-top-right-radius: rem(16);
		}

		form {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			& > div {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(12);

				&.right {
					flex-grow: 1;
					justify-content: flex-end;
				}
			}

			.text {
				height: rem(32);
				background-color: var(--redesigned-color-background-base-2);

				input::placeholder {
					opacity: 1;
					color: var(--redesigned-color-text-primary);
				}
			}

			.select {
				height: rem(32);
				border-color: transparent;
			}

			.reset {
				opacity: 0.32;
				transition: opacity 0.2s;
				margin-left: rem(8);

				&:not(:disabled) {
					@include hover {
						opacity: 0.8;
					}
				}

				&:disabled {
					cursor: not-allowed;
					pointer-events: painted;
				}
			}
		}
	}

	.content {
		flex-grow: 1;
		width: 100%;
		max-width: 100%;
		padding: 0 var(--table-padding);
		overflow: auto;

		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		--firstStickyContent: none;
		--lastStickyContent: none;

		&.firstStuck {
			--firstStickyContent: "";
		}

		&.lastStuck {
			--lastStickyContent: "";
		}

		table {
			width: 100%;
			border-collapse: collapse;

			td {
				white-space: nowrap;
				vertical-align: top;
				background-color: var(--redesigned-color-background-primary);
				background-clip: border-box;

				&:first-child {
					padding-left: rem(20);
				}

				&:last-child {
					padding-right: rem(20);
				}
			}

			thead {
				position: relative;
				z-index: 4;
				box-shadow: inset 0px -1px 0px 0px var(--redesigned-color-separator-opaque);

				td {
					padding: rem(10);
					font-size: rem(12);
					color: var(--redesigned-color-label-secondary);
					border-bottom: 1px solid transparent;
				}
			}

			tbody td {
				padding: rem(12) rem(10);
				font-size: rem(14);
				color: var(--redesigned-color-text-primary);
				border-bottom: 1px solid var(--redesigned-color-separator-opaque);

				&.middle {
					vertical-align: middle;
				}

				&.bottom {
					vertical-align: bottom;
				}

				& > div:first-child {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: rem(4);

					button {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						padding: rem(6);
						gap: 4px;
						margin: rem(-4) 0;
						border: 1px solid transparent;
						border-radius: rem(6);
						opacity: 0.6;
						transition: 0.2s;
						cursor: pointer;

						@include hover {
							opacity: 1;
							border-color: var(--redesigned-color-separator-opaque);
						}

						&:disabled {
							pointer-events: none;
							opacity: 0.2;
						}

						span,
						i {
							color: var(--redesigned-color-text-primary);
							font-size: rem(12);
						}
					}
				}

				.subline {
					font-size: rem(12);
					color: var(--redesigned-color-label-secondary);
				}

				.skeleton {
					min-width: rem(50);
				}
			}

			tbody tr:last-child td {
				border-bottom: 0;
			}

			tbody tr {
				@include hover {
					td {
						background-color: var(--redesigned-color-background-base-3);
					}
				}
			}

			&.firstSticky tbody tr {
				@include hover {
					td:first-child:after {
						background: linear-gradient(
							to right,
							var(--redesigned-color-background-base-3),
							transparent
						);
					}
				}
			}

			&.lastSticky tbody tr {
				@include hover {
					td:last-child:after {
						background: linear-gradient(
							to left,
							var(--redesigned-color-background-base-3),
							transparent
						);
					}
				}
			}

			&.firstSticky td:first-child {
				position: sticky;
				left: var(--table-padding-reverse);
				z-index: 2;

				&:after {
					content: var(--firstStickyContent);
					display: block;
					width: rem(15);
					position: absolute;
					left: calc(100% - 1px);
					top: 0;
					bottom: 0;
					//border-left: 1px solid var(--redesigned-color-separator-opaque);
					background: linear-gradient(
						to right,
						var(--redesigned-color-background-primary),
						transparent
					);
				}
			}

			&.lastSticky td:last-child {
				position: sticky;
				right: var(--table-padding-reverse);
				z-index: 2;

				&:after {
					content: var(--lastStickyContent);
					display: block;
					width: rem(15);
					position: absolute;
					right: calc(100% - 1px);
					top: 0;
					bottom: 0;
					//border-right: 1px solid var(--redesigned-color-separator-opaque);
					background: linear-gradient(
						to left,
						var(--redesigned-color-background-primary),
						transparent
					);
				}
			}
		}
	}

	.empty {
		height: rem(440);
		margin: 0 var(--horizontal-page-padding);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: rem(12);
		position: relative;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			background-image: linear-gradient(
				to bottom,
				transparent,
				transparent calc(100% - 1px),
				var(--redesigned-color-separator-opaque)
			);
			background-size: 100% rem(44);
		}

		img {
			width: rem(50);
			height: rem(56);
		}

		span {
			display: block;
			text-align: center;
			font-size: rem(14);
			line-height: rem(20);
			font-weight: 500;
			color: var(--redesigned-color-text-primary);

			&:last-child {
				font-weight: 400;
				color: var(--redesigned-color-label-secondary);
			}
		}
	}

	.scroll {
		--scroll-height: 1px;
		--scroll-background: var(--redesigned-color-background-base-3);

		&.overlay {
			--scroll-height: 15px;
			--scroll-background: none;
		}

		position: sticky;
		bottom: 0;
		overflow-x: scroll;
		margin-top: calc(var(--scroll-height) * -1);
		background-color: var(--scroll-background);
		transition: var(--table-sticky-transition);
		z-index: 5;
		@include scroll;

		&:not(.full) {
			margin-left: var(--table-padding);
			margin-right: var(--table-padding);
		}

		& > div {
			height: var(--scroll-height);
		}
	}

	&:not(:has(.footer)) .scroll {
		&:not(.full) {
			border-bottom-left-radius: rem(8);
			border-bottom-right-radius: rem(8);
		}

		scrollbar-color: var(--redesigned-color-separator-opaque) transparent;

		&::-webkit-scrollbar-track {
			background: transparent;
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: rem(20);
		min-height: rem(12);
		border-top: 1px solid var(--redesigned-color-separator-opaque);
		background-color: var(--redesigned-color-background-base-3);
		transition: var(--table-sticky-transition);
		z-index: 5;

		&:not(:empty) {
			position: sticky;
			bottom: 0;
			padding: rem(12) rem(20);
		}

		&:not(.full) {
			margin: 0 var(--table-padding);
			border-bottom-left-radius: rem(16);
			border-bottom-right-radius: rem(16);
		}

		&.pagination {
			justify-content: space-between;

			.limits {
				border: 1px solid var(--redesigned-color-separator-non-opaque);
				background: #19191d;
			}

			& > div:nth-child(2) {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(8);

				button:disabled {
					opacity: 1;
					border-color: var(--redesigned-color-separator-opaque);
				}
			}

			& > div:nth-child(3) {
				display: flex;
				flex-direction: row;
				align-items: stretch;
				overflow: hidden;
				min-width: max-content;
				border-radius: rem(8);
				border: 1px solid var(--redesigned-color-separator-non-opaque);

				hr {
					width: 1px;
					margin: 0;
					border: 0;
					background-color: var(--redesigned-color-separator-non-opaque);
				}

				button {
					outline: 0;
					height: rem(32);
					min-width: rem(32);
					padding: 0 rem(10);
					border-radius: rem(8);
					color: var(--redesigned-color-text-primary);
					background: transparent;
				}

				button i {
					color: var(--redesigned-color-text-primary);
					font-size: rem(10);
				}

				button:disabled {
					pointer-events: none;
					opacity: 0.3;
				}
			}
		}
	}
}
