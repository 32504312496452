@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.header {
  position: sticky;
  top: 0;
}

.layout {
  --sidebar-width: #{rem(280)};

  --content-width: calc(100% - var(--sidebar-width));

  @media screen and (max-width: 1365px) {
    --content-width: 100%
  }

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .sideBar {
    position: sticky;
    top: var(--header-height);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    height: calc(100vh - var(--header-height));
    padding: rem(24) rem(16);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(12);
    border-right: rem(1) solid var(--redesigned-color-separator-opaque);
    background-color: var(--redesigned-color-background-base-3);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: var(--content-width);
    max-width: var(--content-width);
    min-height: calc(100vh - var(--header-height));
    padding: var(--page-padding);

    & > div {
      flex-grow: 1;
    }

    & > footer:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: rem(20);
      margin-top: rem(70);

      span {
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(20);
        text-align: center;
        color: var(--redesigned-color-label-tertiary);
      }
    }
  }
}
