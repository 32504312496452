@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.checkbox {
	--checkbox-color: var(--redesigned-color-label-secondary);

	@include hover {
		--checkbox-color: var(--redesigned-color-text-primary);
	}

	&:has(> input:checked) {
		--checkbox-color: var(--redesigned-color-text-primary);
	}

	&.light {
		--checkbox-color: #1c1c1c;

		@include hover {
			--checkbox-color: rgba(28, 28, 28, 0.6);
		}

		&:has(> input:checked) {
			--checkbox-color: #1c1c1c;
		}
	}

	display: flex;
	align-items: center;
	gap: rem(4);
	cursor: pointer;

	.checkmark {
		position: relative;
		display: flex;
		width: rem(16);
		height: rem(16);
		min-width: rem(16);
		min-height: rem(16);

		&.error:before {
			border-color: var(--redesigned-color-accent-red) !important;
		}

		&::before {
			content: "";
			display: flex;
			border-radius: rem(4);
			border: rem(1) solid var(--checkbox-color);
			transition: 0.2s ease-in-out;
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
		}

		&::after {
			content: "";
			display: flex;
			position: absolute;
			width: rem(12);
			height: rem(12);
			left: 50%;
			opacity: 0;
			transition: 0.2s ease-in-out;
			top: 50%;
			transform: translate(-50%, -50%);
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			background-size: 100%;
		}
	}

	&:has(> input:disabled) {
		opacity: 0.32;
		pointer-events: none;
	}

	& > input {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: 0;

		&:checked + .checkmark::before {
			border-color: var(--redesigned-color-accent-purple) !important;
			background-color: var(--redesigned-color-accent-purple);
		}

		&:checked + .checkmark::after {
			opacity: 1;
		}
	}

	.content {
		color: var(--checkbox-color);
		font-size: rem(14);
		font-weight: 400;
		white-space: break-spaces;
		transition: color 0.2s ease-in-out;

		a {
			color: var(--redesigned-color-accent-purple);
			font-size: rem(14);
			font-weight: 400;
			text-decoration-line: underline;
		}
	}
}
