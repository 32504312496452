@import "styles/redesigned/functions";
@import "styles/redesigned/media";

$padding: rem(4);

.switcher {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: $padding;
  padding: $padding;
  position: relative;
  width: min-content;
  overflow: hidden;
  border-radius: rem(12);
  background-color: var(--redesigned-color-background-base-1);

  &.full {
    width: 100%;
  }

  button {
    flex-grow: 1;
    position: relative;
    height: rem(28);
    padding: rem(4) rem(8);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(16);
    cursor: pointer;
    border-radius: rem(8);
    border: rem(1) solid transparent;
    color: var(--redesigned-color-text-primary);
    transition: 0.2s;

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    @include hover {
      border-color: var(--redesigned-color-separator-opaque);
    }

    &:active {
      box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
    }

    &.selected {
      pointer-events: none;
    }

    span {
      white-space: nowrap;
      position: relative;
      z-index: 1;
    }
  }

  .slider {
    position: absolute;
    top: $padding;
    bottom: $padding;
    border-radius: rem(8);
    background-color: var(--redesigned-color-background-base-2);
    transition: 0.3s;
  }
}
