@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.dateInput {
	position: relative;

	& > button {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rem(4);
		width: 100%;
		min-width: rem(100);
		height: rem(32);
		padding: 0 rem(10);
		border-radius: rem(8);
		overflow: hidden;
		box-sizing: border-box;
		border: rem(1) solid var(--redesigned-color-background-base-1);
		background-color: var(--redesigned-color-background-base-1);
		transition: border-color 0.2s;

		&.opened {
			border-color: var(--redesigned-color-separator-opaque);
		}

		&:not(:disabled) {
			@include hover {
				border-color: var(--redesigned-color-separator-opaque);
			}
		}

		& > * {
			transition: opacity 0.2s;
		}

		&:disabled {
			cursor: not-allowed;

			& > * {
				opacity: 0.3;
			}

			.clear {
				pointer-events: none;
			}
		}

		i {
			font-size: rem(16);
			color: var(--redesigned-color-text-primary);
		}

		span {
			font-size: rem(14);
			font-weight: 500;
			color: var(--redesigned-color-text-primary);
		}

		.clear {
			height: 100%;
			flex-grow: 1;
			margin-right: rem(-10);
			padding: rem(4);

			& > div {
				display: flex;
				align-items: center;
				justify-content: center;
				float: right;
				height: 100%;
				aspect-ratio: 1;
				border: 0;
				background: none;
				border: rem(1) solid transparent;
				border-radius: rem(6);
				opacity: 0.6;
				transition: opacity 0.2s, border-color 0.2s;

				@include hover {
					border-color: var(--redesigned-color-separator-opaque);
					opacity: 1;
				}
			}
		}
	}

	& > .popup {
		position: absolute;
		left: rem(-6);
		top: calc(100% + #{rem(4)});
		width: rem(280);
		z-index: 100;
		border-radius: rem(12);
		overflow: hidden;
		border: rem(1) solid var(--redesigned-color-separator-opaque);
		background-color: var(--redesigned-color-background-base-1);
		transition: width 0.2s;

		&.month {
			width: rem(320);

			.calendar {
				width: rem(320);
			}
		}

		&.top {
			top: auto;
			bottom: calc(100% + #{rem(4)});
		}

		.tabs {
			padding: rem(12) rem(16) 0;
			border-bottom: rem(1) solid var(--redesigned-color-separator-opaque);

			& > div {
				margin-bottom: rem(-1);
			}
		}

		.calendar {
			height: rem(280);
		}

		.selected {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: rem(6) rem(16);
			border-top: rem(1) solid var(--redesigned-color-separator-opaque);

			span {
				font-size: rem(14);
				font-weight: 400;
				color: var(--redesigned-color-label-secondary);
			}

			strong {
				font-size: rem(12);
				font-weight: 500;
				line-height: rem(16);
				padding: rem(2) rem(6);
				border-radius: rem(4);
				color: var(--redesigned-color-text-primary);
				background-color: var(--redesigned-color-background-base-2);
			}
		}

		.value {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: rem(8);
			padding: rem(12) rem(16);
			border-top: rem(1) solid var(--redesigned-color-separator-opaque);

			span {
				font-size: rem(14);
				font-weight: 500;
				color: var(--redesigned-color-text-primary);
			}

			strong {
				flex-grow: 1;
				font-size: rem(14);
				font-weight: 400;
				line-height: rem(20);
				padding: rem(6);
				border-radius: rem(8);
				text-align: center;
				color: var(--redesigned-color-text-primary);
				background-color: var(--redesigned-color-background-base-2);
			}
		}
	}
}
