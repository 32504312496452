@import "src/assets/styles/common/mixins";

.adjustLeverageModal {
	--offset: 8px;
	--radius: 8px;
	--padding: 16px;
	--header-font: 24px;

	width: 425px;
	max-width: 100%;
	padding: var(--padding);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: var(--padding);
	overflow-y: auto;
	scrollbar-width: auto;
	scrollbar-gutter: stable;
	border-radius: var(--radius);
	color: var(--color-text-primary);
	background-color: var(--main-background-color);


	.header {
		top: calc(var(--padding) * -1);
		position: sticky;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--padding);
		padding: var(--padding);
		margin: calc(var(--padding) * -1);
		backdrop-filter: blur(20px);
		z-index: 10;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			z-index: -1;
			background-color: var(--main-background-color);
		}

		span {
			flex-grow: 1;
			font-size: var(--header-font);
			font-weight: 500;
		}
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: stretch;

		input {
			text-align: center;
		}

		.range {
			padding: 30px 5px 10px;
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		gap: 8px;

		& > * {
			flex-grow: 1;
		}
	}
}
