@import "./functions";
@import "./media";

:root {
  --vertical-page-padding: #{rem(24)};
  --horizontal-page-padding: #{rem(40)};

  --page-padding: var(--vertical-page-padding) var(--horizontal-page-padding);

  --header-height: 47px;

  @media screen and (max-width: 1024px) {
    --header-height: 65px;
  }
}
