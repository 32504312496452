@import "styles/redesigned/media";
@import "styles/redesigned/mixins";

.calendar .days-scroll {
	flex-grow: 1;
	overflow-y: scroll;
	@include scroll;

	& > .days {
		position: relative;
		overflow: hidden;
		min-height: 100%;

		& > .month {
			position: absolute;
			width: 100%;
			display: grid;
			align-content: start;
			grid-template-columns: repeat(7, 1fr);

			& > .day {
				position: relative;
				cursor: pointer;

				& > div {
					display: flex;
					align-items: stretch;
					padding: 2px 0;
					height: 100%;
					position: relative;
					z-index: 2;

					& > div {
						flex-grow: 1;
						display: flex;
						align-items: stretch;

						& > div {
							flex-grow: 1;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}

				.today {
					position: absolute;
					box-sizing: border-box;
					left: 50%;
					top: 50%;
					border: 2px solid;
					pointer-events: none;
				}

				&.inactive {
					pointer-events: none;
					opacity: 0.3;
				}

				&.week-day-1 { grid-column-start: 1; }
				&.week-day-2 { grid-column-start: 2; }
				&.week-day-3 { grid-column-start: 3; }
				&.week-day-4 { grid-column-start: 4; }
				&.week-day-5 { grid-column-start: 5; }
				&.week-day-6 { grid-column-start: 6; }
				&.week-day-7 { grid-column-start: 7; }
			}

			.month-name {
				grid-column-start: 1;
				grid-column-end: -1;
				z-index: 1;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
			}
		}
	}
}
