.ellipser {
  display: inline-flex;
  flex-direction: row;
  height: 1lh;
  overflow: hidden;
  user-select: none;

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: max-content;
  }

  & > div:last-child {
    flex-direction: row-reverse;
  }
}
