@import "styles/redesigned/functions";
@import "styles/redesigned/media";

.menuButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: rem(44);
  padding: 0 rem(12);
  gap: rem(8);
  border-radius: rem(12);
  border: rem(1) solid transparent;
  cursor: pointer;
  transition: 0.2s;

  &.active {
    background-color: var(--redesigned-color-background-base-1);
  }

  &:disabled, &.disabled {
    opacity: 0.3;
  }

  &.active, &:disabled, &.disabled {
    pointer-events: none;
  }

  @include hover {
    border-color: var(--redesigned-color-separator-opaque);
    background-color: var(--redesigned-color-background-base-1);
  }

  &:active {
    box-shadow: inset 0 0 0 rem(1) var(--redesigned-color-separator-opaque);
  }

  img {
    width: rem(20);
    height: rem(20);
    object-fit: contain;
  }

  span {
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    color: var(--redesigned-color-text-primary);
  }
}
